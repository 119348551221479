import React, { useState } from 'react'
import { Header, Accordion, List, Icon } from 'semantic-ui-react'
import { IColumnInfoGroup, IColumnInfoData } from './FilePreview'

interface IFilePreviewColumnInfo {
  columnInfo: IColumnInfoData
  totalRowCount: number
}

const FilePreviewColumnInfo = React.memo(
  ({ columnInfo, totalRowCount }: IFilePreviewColumnInfo) => {
    return (
      <React.Fragment>
        <Header size="tiny">{columnInfo.original}</Header>
      </React.Fragment>
    )
  },
)

export default FilePreviewColumnInfo
