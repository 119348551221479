import React from 'react'
import { OrganizationSettings } from './OrganizationSettings'
import { UserSettings } from './UserSettings'
import config from '../config'
import axios from 'axios'
import { showError } from './FilePreview'
import { Button, Loader } from 'semantic-ui-react'
import AuthContext from '../AuthContext'
import { ChangePasswordSettings } from './ChangePasswordSettings'
import { ProjectSettings} from './ProjectSettings'
import './Settings.scss'

const fetchOrganizations = async () => {
  const url = `${config.ORGANIZAITON_URL}`
  try {
    const { data } = await axios.get(url)
    return data
  } catch (e) {
    showError(e)
  }
}

export interface IOrganization {
  name: string
  id: string
  has_logo: boolean
  logo_base64: string | undefined
}

export const Settings = () => {
  const [organizations, setOrganizations] = React.useState<IOrganization[]>([])
  const [loading, setLoading] = React.useState(true)
  const { jwtContent } = React.useContext(AuthContext)

  const handleFetchOrganizations = async () => {
    setLoading(true)
    const response = await fetchOrganizations()
    if (response !== undefined) {
      const newOrganizations = response.data
      setOrganizations(newOrganizations)
    }
    setLoading(false)
  }

  React.useEffect(() => {
    if (jwtContent!.isAdmin !== true) {
      setLoading(false)
      return
    }
    handleFetchOrganizations()
  }, [])

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <h1>Settings</h1>
          <ChangePasswordSettings />
          <ProjectSettings />
          {jwtContent!.isAdmin && (
            <>
              <OrganizationSettings
                organizations={organizations}
                handleFetchOrganizations={handleFetchOrganizations}
              />
              <UserSettings organizations={organizations} />
            </>
          )}
        </div>
      )}
    </div>
  )
}
