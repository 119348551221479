import React, { useState } from 'react'
import { ITopicModel } from '../routes/TopicModels'
import {
  Button,
  Checkbox,
  Header,
  Icon,
  Loader,
  Modal,
} from 'semantic-ui-react'
import config from '../config'
import './TopicModelDownloadXlsxModal.scss'

import {
  fetchFileInfo,
  IColumnInfo,
  IColumnInfoData,
  showError,
} from './FilePreview'
import axios from 'axios'

interface ITopicModelDownloadXlsxModalProps {
  topicModel: ITopicModel
  closeModal: () => void
  threshold: number
}

export interface IKeyMap {
  [key: string]: boolean
}

export const TopicModelDownloadXlsxModal = ({
  topicModel,
  closeModal,
  threshold,
}: ITopicModelDownloadXlsxModalProps) => {
  const [columnInfoData, setColumnInfoData] = useState<IColumnInfoData[]>([])
  const [selectedFieldIds, setSelectedFieldIds] = useState<IKeyMap>({})
  const [fetching, setFetching] = useState(false)

  const downloadFunc = async () => {
    setFetching(true)
    const apiUrl = `${config.TOPIC_MODELS_URL}/${topicModel!.id}.xlsx`
    const includeKeys = Object.entries(selectedFieldIds)
      .filter(a => a[1])
      .map(a => a[0])
    const includeKeysString = includeKeys.join(',')

    let response
    try {
      response = await axios.get(apiUrl, {
        params: {
          extraFields: includeKeysString,
          threshold,
        },
        responseType: 'blob',
      })
    } catch (e) {
      showError(e)
    }
    if (response) {
      const a = document.createElement('a')

      const blob = response.data
      const windowUrl = window.URL || window.webkitURL
      const url = windowUrl.createObjectURL(blob)
      a.href = url
      const fileName = `${topicModel!.document_name}_${topicModel!.name}.xlsx`
      a.download = fileName
      a.click()
      windowUrl.revokeObjectURL(url)
      closeModal()
    }
    setFetching(false)
  }

  const fetchAndSetFileInfo = async () => {
    const { data } = await fetchFileInfo({ fileId: topicModel.document_id })
    setColumnInfoData(data)
    console.log('asdf', data)
  }

  React.useEffect(() => {
    fetchAndSetFileInfo()
  }, [])

  return (
    <>
      {/*<Header icon="cogs" content="Topic Model Parameters" />*/}
      <Modal.Content>
        {columnInfoData.length === 0 ? (
          <Loader active inline />
        ) : (
          <div className={'included-fields-selection'}>
            <Header>Extra fields to include:</Header>
            {columnInfoData.map(info => {
              return (
                <Checkbox
                  key={info.slugified}
                  checked={selectedFieldIds[info.slugified]}
                  label={info.original}
                  onClick={() => {
                    const key = info.slugified
                    const newVal = !selectedFieldIds[info.slugified]
                    const newState: IKeyMap = { ...selectedFieldIds }
                    newState[key] = newVal
                    setSelectedFieldIds(newState)
                  }}
                />
              )
            })}
          </div>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button color="green" onClick={downloadFunc} loading={fetching}>
          <Icon name="checkmark" />
          Download
        </Button>
      </Modal.Actions>
    </>
  )
}
