import React from 'react'
import { ITopicModel } from '../routes/TopicModels'
import config from '../config'
import axios from 'axios'
import { showError } from './FilePreview'
import './TopicModelTab.scss'
import { Link } from 'react-router-dom'
import { Loader, Table } from 'semantic-ui-react'

const loadData = async (documentId: string) => {
  const url = `${config.TOPIC_MODELS_URL}`
  try {
    const { data } = await axios.get(url, {
      params: { document_id: documentId, status: 'done', n_page: 9999},
    })
    return data
  } catch (e) {
    showError(e)
  }
}

interface ITopicModelTabProps {
  activeTopicModel: ITopicModel
  activeTopicModelId: string
}

export const TopicModelTab = ({
  activeTopicModel,
  activeTopicModelId,
}: ITopicModelTabProps) => {
  const [topicModels, setTopicModels] = React.useState<ITopicModel[]>([])
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    ;(async () => {
      const response = await loadData(activeTopicModel.document_id)
      if (response) {
        const topicModelsResponse: ITopicModel[] = response.data
        setTopicModels(topicModelsResponse)
        setLoading(false)
      }
    })()
  }, [])

  return (
    <div className={'topic-model-tab'}>
      {loading ? (
        <Loader active inline />
      ) : (
        topicModels.map(topicModel => {
          return (
            <div className={'link-container'} key={topicModel.id}>
              <Link
                to={`${config.ROUTES.topic_models}/${topicModel.id}`}
                key={topicModel.id}
                className={topicModel.id === activeTopicModelId ? 'active' : ''}
              >
                {topicModel.name ? topicModel.name : topicModel.document_name}
              </Link>
            </div>
          )
        })
      )}
    </div>
  )
}
