import React, { useMemo } from 'react'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import {
  IColumnInfo,
  IFileContent,
  IFileDataRow,
  IFileCell,
  IColumnInfoData,
} from './FilePreview'
import { Label } from 'semantic-ui-react'
import { getFilterColumnsOrDefault } from './FilePreviewFilters'
import ColumnInfoPanel from './FilePreviewColumnInfo'

type IOnFetchData = (info: ITablePageInfo) => void

export interface ITablePageInfo {
  page: number
  pageSize: number
}

interface IFilePreviewContentProps {
  fileContent: IFileContent
  columnInfo: IColumnInfo
  loading: boolean
  filterColumnsString: string | undefined
  page: number
  pageSize: number
  onFetchData: IOnFetchData
}

const FilePreviewContent = ({
  fileContent: {
    data,
    meta: { count },
  },
  columnInfo,
  filterColumnsString,
  page,
  pageSize,
  loading,
  onFetchData,
}: IFilePreviewContentProps) => {
  const cells = useMemo(
    () =>
      data.map((item: IFileDataRow) =>
        Object.fromEntries(
          item.cells.map((cell: IFileCell) => [cell.slug, cell]),
        ),
      ),
    [data],
  )
  const columns = useMemo(() => getColumns(columnInfo, filterColumnsString), [
    columnInfo,
    filterColumnsString,
  ])
  const pages = 1 + Math.floor(count / pageSize)

  return (
    <ReactTable
      data={cells}
      columns={columns}
      pages={pages}
      loading={loading}
      page={page}
      resizable
      minRows={0}
      sortable={false}
      manual
      onFetchData={onFetchData}
      pageSize={pageSize}
    />
  )
}

interface ITableCell {
  value: IFileCell
}

const getColumns = (
  { data, meta }: IColumnInfo,
  filterColumnsString: string = '',
) => {
  const filterColumns = getFilterColumnsOrDefault(filterColumnsString)
  const getColorForScore = (score: number) => {
    let color: 'green' | 'olive' | 'yellow' | 'orange' | 'red' = 'green'
    if (score > 0.2) {
      color = 'olive'
    }
    if (score > 0.4) {
      color = 'yellow'
    }
    if (score > 0.6) {
      color = 'orange'
    }
    if (score > 0.8) {
      color = 'red'
    }
    return color
  }
  return [
    {
      Cell: (row: any) => {
        return <div>{row.index + 1 + row.page * row.pageSize}</div>
      },
      Header: '',
      filterable: false,
      id: 'row',
      maxWidth: 50,
    },
    ...data.map((info: IColumnInfoData) => ({
      Cell: ({ value: { score, value } }: ITableCell) => {
        if (score !== null) {
          return (
            <span>
              {value}
              <Label className={'score-label'} color={getColorForScore(score)}>
                {Math.floor(100 * score)}
              </Label>
            </span>
          )
        }
        return <span>{value}</span>
      },
      Header: (
        <ColumnInfoPanel columnInfo={info} totalRowCount={meta.total_count} />
      ),
      accessor: info.slugified,
      minWidth: 300,
      show: !filterColumns.includes(info.slugified),
    })),
  ]
}

export default FilePreviewContent
