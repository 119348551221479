import React, {useCallback} from 'react'
import axios from 'axios'
import {RouteComponentProps} from 'react-router'
import {Breadcrumb, Button, Icon, Dropdown} from 'semantic-ui-react'
import {Link} from 'react-router-dom'
import config from '../config'
import {toast} from 'react-toastify'
import FilePreviewComponent, {
  getFilterStrings,
  showError,
} from '../components/FilePreview'
import TopicModelModal from '../components/TopicModelModal'
import FileStatModal from '../components/FileStatModal'
import AuthContext from '../AuthContext'

export interface IFilePreviewProps {
  fileId: string
}

export interface ITopicModelParams {
  passes: number
  topics: number
  name: string
  selectedSentimentIds: string[]
}

const FilePreview = ({
                       match: {
                         params: {fileId},
                       },
                       location: {search},
                     }: RouteComponentProps<IFilePreviewProps>) => {
  const {jwtContent} = React.useContext(AuthContext)

  const newTopicModel = useCallback(
    async ({
             passes,
             topics,
             name,
             selectedSentimentIds,
           }: ITopicModelParams) => {
      const url = `${config.TOPIC_MODELS_URL}`
      const {filterColumnsString, filterRowsString} = getFilterStrings(search)
      try {
        await axios.post(url, {
          filter_columns: filterColumnsString,
          filter_rows: filterRowsString,
          id: fileId,
          name,
          param_number_of_passes: passes,
          param_number_of_topics: topics,
          selected_sentiment_ids: selectedSentimentIds.join(','),
        })
        toast.success('New topic model created')
        return true
      } catch (e) {
        showError(e)
      }
      return false
    },
    [search, fileId],
  )

  const newForceAtlas = useCallback(async () => {
    const url = `${config.GRAPH_URL}`
    try {
      await axios.post(url, {id: fileId})
      toast.success('New graph created')
    } catch (e) {
      showError(e)
    }
  }, [fileId])

  const buttonComponent = (
    <Dropdown
      text={'Options'}
      button
      className={'green'}
    >
      <Dropdown.Menu direction={'left'}>
        <FileStatModal fileId={fileId} search={search}/>
        <Dropdown.Item
          as={Link}
          to={`${config.ROUTES.files}/${fileId}/spell-check`}
        >
          <Icon name={'check'} />
          Spell Check
        </Dropdown.Item>
        <TopicModelModal onSubmit={newTopicModel}/>
        <Dropdown.Item
          as={Link}
          to={`${config.ROUTES.files}/${fileId}/predict`}
        >
          <Icon name={'plus'} />
          New prediction
        </Dropdown.Item>
        {jwtContent!.isAdmin && (
          <>
            <Dropdown.Item color={'green'} onClick={newForceAtlas}>
              <Icon name={'plus'} />
              New graph
            </Dropdown.Item>
            <Dropdown.Item
              as={Link}
              to={`${config.ROUTES.files}/${fileId}/create-training-set`}
            >
              <Icon name={'plus'} />
              New training set
            </Dropdown.Item>
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  )

  return (
    <React.Fragment>
      <div className={'breadcrumb-container'}>
        <Breadcrumb size={'large'}>
          <Breadcrumb.Section link as={Link} to={config.ROUTES.files}>
            Files
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon={'right angle'}/>
          <Breadcrumb.Section>{fileId}</Breadcrumb.Section>
        </Breadcrumb>
        {buttonComponent}
      </div>
      <FilePreviewComponent/>
    </React.Fragment>
  )
}

export default FilePreview
