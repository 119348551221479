import React, { useEffect, useState } from 'react'
import {
  Pagination,
  PaginationProps,
  Segment,
  Table,
  TableProps,
} from 'semantic-ui-react'
import DimmedLoader from './DimmedLoader'
import { showError } from './FilePreview'
import AuthContext from '../AuthContext'

export interface IPaginatedQueryResult<T> {
  data: T[]
  page: number
  n_pages: number
}

interface IPaginatedTableProps<T> {
  headerRow: JSX.Element
  rowElementGetter: (x: T) => JSX.Element
  dataGetter: (page: number) => Promise<IPaginatedQueryResult<T>>
  updateSignal?: number
}

const PaginatedTable = ({
  headerRow,
  rowElementGetter,
  dataGetter,
  updateSignal = 0,
  ...tableProps
}: IPaginatedTableProps<any> & TableProps) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [data, setData] = useState<any[]>([])
  const [page, setPage] = useState<number>(1)
  const [nPages, setNPages] = useState<number>(1)

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true)
        const { data: newData, n_pages } = await dataGetter(page)
        setData(newData)
        setNPages(n_pages)
      } catch (e) {
        showError(e)
      } finally {
        setLoading(false)
      }
    }
    getData()
  }, [dataGetter, page, updateSignal])

  const onPageChange = (_: unknown, eventData: PaginationProps) => {
    const activePage: any = eventData.activePage
    setPage(activePage)
  }

  return (
    <div className={'paginated-table-container'}>
      {loading ? (
        <Segment>
          <DimmedLoader />
        </Segment>
      ) : data.length > 0 ? (
        <React.Fragment>
          <Table {...tableProps}>
            <Table.Header>{headerRow}</Table.Header>
            <Table.Body>
              {data.map((dataItem: any) => {
                return rowElementGetter(dataItem)
              })}
            </Table.Body>
          </Table>
          {nPages > 1 && (
            <Pagination
              activePage={page}
              totalPages={nPages}
              onPageChange={onPageChange}
            />
          )}
        </React.Fragment>
      ) : (
        <p>No data.</p>
      )}
    </div>
  )
}

export default PaginatedTable
