import React, { useEffect, useState } from 'react'
import axios from 'axios'
import config from '../config'
import { RouteComponentProps } from 'react-router'
import DimmedLoader from '../components/DimmedLoader'
import {
  Breadcrumb,
  Button,
  ButtonGroup,
  Checkbox,
  Icon,
  Input,
  Loader,
  Pagination,
  PaginationProps,
  Progress,
  Table,
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { showError } from '../components/FilePreview'
import { toast } from 'react-toastify'

interface ISpellCheckResponseItem {
  original: string
  corrected: string[]
  count: number
}

interface ISpellCheckResponse {
  data: ISpellCheckResponseItem[]
  status: string
  id: string
  progress: number | null
}

interface IListItem {
  original: string
  choices: string[]
  selected: string
  checked: boolean
  count: number
}

interface ISpellCheckProps {
  fileId: string
}

const pageSize = config.SPELL_CHECK_PAGE_SIZE
const refreshTimeout = config.SPELL_CHECK_REFRESH_TIMEOUT

const kickOffSpellCheck = async (spellCheckId: string, corrections: any) => {
  const url = `${config.SPELL_CHECK_URL}/${spellCheckId}/start`
  try {
    await axios.post(url, { data: corrections })
    toast.success(
      'Spell check kicked off. You can find the new file in the Files tab soon.',
    )
  } catch (e) {
    showError(e)
  }
}

const mapDataToChoices = (data: IListItem[]) => {
  return data
    .filter(({ checked }: IListItem) => checked)
    .map(({ original, selected }: IListItem) => {
      return {
        original,
        selected,
      }
    })
}

interface ISpellCheckRowInputProps {
  item: IListItem
  changeCheckedStatus: (original: string) => void
  changeCheckedChoice: (original: string, value: string) => void
}

const SpellCheckRowInput = ({
  item,
  changeCheckedChoice,
  changeCheckedStatus,
}: ISpellCheckRowInputProps) => {
  const [otherValue, setOtherValue] = React.useState('')

  const onClickCheckbox = () => {
    changeCheckedStatus(item.original)
  }
  return (
    <Table.Row key={item.original} className={item.checked ? '' : 'unchecked'}>
      <Table.Cell>
        <Checkbox checked={item.checked} onClick={onClickCheckbox} />
      </Table.Cell>
      <Table.Cell>
        {item.original}
        <Icon name={'long arrow alternate right'} />
        <span className={'spell-chooser'}>
          {item.choices.map(choice => {
            const onClickSpan = () => {
              changeCheckedChoice(item.original, choice)
            }
            return (
              <span
                key={choice}
                className={choice !== item.selected ? 'inactive' : ''}
                onClick={onClickSpan}
              >
                {choice}
              </span>
            )
          })}
        </span>
        <Input
          placeholder={'Other'}
          value={otherValue}
          onChange={event => {
            const newString: string = event.target.value
            if (newString === '') {
              changeCheckedChoice(item.original, item.choices[0])
            } else {
              changeCheckedChoice(item.original, newString)
            }
            setOtherValue(newString)
          }}
        />
      </Table.Cell>
      <Table.Cell>{item.count}</Table.Cell>
    </Table.Row>
  )
}

interface ISpellCheckListProps {
  spellCheckResponse: ISpellCheckResponse
}

interface ICurrentStatusMap {
  [key: string]: IListItem
}

const SpellCheckView = ({ spellCheckResponse }: ISpellCheckListProps) => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [listData, setListData] = useState<IListItem[]>([])
  const [choiceCount, setChoiceCount] = useState<number>(0)

  useEffect(() => {
    setListData((currentListData: IListItem[]) => {
      const currentStatus: ICurrentStatusMap = Object.fromEntries(
        currentListData.map(item => [item.original, item]),
      )
      return spellCheckResponse.data.map(
        ({ original, count, corrected: choices }: ISpellCheckResponseItem) => {
          const originalListItem = currentStatus[original]
          const checked = originalListItem ? originalListItem.checked : true
          const selected = originalListItem
            ? originalListItem.selected
            : choices[0]

          return {
            checked,
            choices,
            count,
            original,
            selected,
          }
        },
      )
    })
  }, [spellCheckResponse])

  useEffect(() => {
    setChoiceCount(mapDataToChoices(listData).length)
  }, [listData])

  const changeCheckedStatus = (original: string) => {
    setListData(
      listData.map((item: IListItem) => {
        if (item.original !== original) {
          return item
        }
        return {
          ...item,
          checked: !item.checked,
        }
      }),
    )
  }

  const changeCheckedChoice = (original: string, choice: string) => {
    setListData(
      listData.map((item: IListItem) => {
        if (item.original !== original) {
          return item
        }
        return {
          ...item,
          selected: choice,
        }
      }),
    )
  }

  const kickOff = () => {
    const corrections = mapDataToChoices(listData)
    kickOffSpellCheck(spellCheckResponse!.id, corrections)
  }

  const onPageChange = (_: any, { activePage }: PaginationProps) => {
    const castedActivePage: number = Number(activePage!)
    setCurrentPage(castedActivePage)
  }

  if (
    spellCheckResponse!.status === 'created' ||
    spellCheckResponse!.progress === null
  ) {
    return (
      <Loader active inline="centered">
        Waiting... Spell checking is in the queue...
      </Loader>
    )
  }

  return (
    <>
      {spellCheckResponse!.status !== 'done' && (
        <div>
          <Progress
            percent={Math.floor(100 * (spellCheckResponse!.progress as number))}
            progress={'percent'}
            color={'green'}
            active
          >
            Analyzing the file
          </Progress>
        </div>
      )}
      <Table className={'spell-check-table'}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Use</Table.HeaderCell>
            <Table.HeaderCell>Correction</Table.HeaderCell>
            <Table.HeaderCell>Count</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {listData
            .slice(pageSize * (currentPage - 1), pageSize * currentPage)
            .map((item: IListItem) => {
              return (
                <SpellCheckRowInput
                  key={item.original}
                  item={item}
                  changeCheckedChoice={changeCheckedChoice}
                  changeCheckedStatus={changeCheckedStatus}
                />
              )
            })}
        </Table.Body>
      </Table>
      {listData.length > pageSize && (
        <Pagination
          onPageChange={onPageChange}
          activePage={currentPage}
          totalPages={Math.ceil(listData.length / pageSize)}
        />
      )}
      <ButtonGroup floated={'right'}>
        <Button
          color={'green'}
          disabled={spellCheckResponse!.status !== 'done'}
          onClick={kickOff}
        >
          <Loader inline />
          Run spell check with {choiceCount} distinct corrections
        </Button>
      </ButtonGroup>
    </>
  )
}

const SpellCheck = ({
  match: {
    params: { fileId },
  },
}: RouteComponentProps<ISpellCheckProps>) => {
  const [spellCheckResponse, setSpellCheckResponse] = useState<
    undefined | ISpellCheckResponse
  >(undefined)
  const [initialLoading, setInitialLoading] = useState<boolean>(true)

  useEffect(() => {
    const getData = async (): Promise<ISpellCheckResponse | undefined> => {
      const url = `${config.SPELL_CHECK_URL}`
      try {
        const { data: spellCheck } = await axios.get(url, {
          params: { id: fileId },
        })
        return spellCheck
      } catch (e) {
        showError(e)
      }
    }
    const timeoutFunction = async () => {
      const spellCheck = await getData()
      if (spellCheck !== undefined) {
        setSpellCheckResponse(spellCheck)
        setInitialLoading(false)
        if (spellCheck.status !== 'done') {
          timeoutId = setTimeout(timeoutFunction, refreshTimeout)
        }
      }
    }

    let timeoutId = setTimeout(timeoutFunction, 0)

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [fileId])

  if (initialLoading) {
    return <DimmedLoader />
  }

  return (
    <div>
      <Breadcrumb size={'large'}>
        <Breadcrumb.Section link as={Link} to={config.ROUTES.files}>
          Files
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon={'right angle'} />
        <Breadcrumb.Section
          link
          as={Link}
          to={`${config.ROUTES.files}/${fileId}`}
        >
          {fileId}
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon={'right angle'} />
        <Breadcrumb.Section>Apply Spell Checking</Breadcrumb.Section>
      </Breadcrumb>
      {spellCheckResponse && (
        <SpellCheckView spellCheckResponse={spellCheckResponse} />
      )}
    </div>
  )
}

export default SpellCheck
