import React from 'react'
import { Header } from 'semantic-ui-react'

export interface IFilePreviewHeaderProps {
  rowCount: number
  fileId: string
  totalRowCount: number
  loading: boolean
}

const FilePreviewHeader = React.memo(
  ({
    rowCount,
    fileId,
    totalRowCount,
    loading,
  }: IFilePreviewHeaderProps) => {
    // const fileName = decodeURIComponent(selectedFile)
    if (loading) {
      return <Header>{`Loading "${fileId}"`}</Header>
    }
    const percentage = ((rowCount / totalRowCount) * 100).toFixed(2)
    return (
      <Header>
        {`${rowCount} / ${totalRowCount} lines (${percentage}% of "${fileId}")`}
      </Header>
    )
  },
)

export default FilePreviewHeader
