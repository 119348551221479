import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import ErrorBoundary from './components/ErrorBoundary'
import { toast, ToastContainer } from 'react-toastify'
import config from './config'
import 'react-toastify/dist/ReactToastify.css'
import { Login } from './components/Login'
import MainMenu from './components/MainMenu'
import Routes from './routes'
import AuthContext, { AuthenticationProvider, IJwtContent } from './AuthContext'
import axios from 'axios'
import './App.scss'
import { ProjectProvider } from './ProjectContext'

const InnerApp = () => {
  axios.defaults.baseURL = config.API_URL

  const { jwtContent, token } = React.useContext(AuthContext)

  return (
    <>
      {token === undefined ? (
        <p>Loading..</p>
      ) : (
        <React.Fragment>
          {token === null || jwtContent === null || jwtContent === undefined ? (
            <Login />
          ) : (
            <ProjectProvider>
              <React.Fragment>
                <MainMenu />
                <div className={'content-container'}>
                  <Routes />
                </div>
              </React.Fragment>
            </ProjectProvider>
          )}
        </React.Fragment>
      )}
      <ToastContainer position="top-center" autoClose={config.TOAST_DELAY} />
    </>
  )
}

const App = () => {
  return (
    <Router>
      <AuthenticationProvider>
        <ErrorBoundary>
          <InnerApp />
        </ErrorBoundary>
      </AuthenticationProvider>
    </Router>
  )
}

export default App
