import React from 'react'
import {
  Button,
  Checkbox,
  Dropdown,
  Header,
  Input,
  Modal,
  Table,
} from 'semantic-ui-react'
import { toast } from 'react-toastify'
import { IUser } from './UserSettings'
import './UserSettingsRow.scss'
import { IOrganization } from './Settings'
import config from '../config'
import axios from 'axios'
import { showError } from './FilePreview'

const deleteUser = async (userId: string) => {
  const url = `${config.USER_URL}/${userId}/`
  try {
    await axios.delete(url)
  } catch (e) {
    showError(e)
  }
}

const putUser = async (
  userId: string,
  isAdmin: boolean,
  organizationId: string,
  email: string,
) => {
  const url = `${config.USER_URL}/${userId}`
  try {
    const { data } = await axios.put(url, {
      is_admin: isAdmin,
      organization_id: organizationId,
      email,
    })
    return data
  } catch (e) {
    showError(e)
  }
}

const resetPassword = async (userId: string) => {
  const url = `${config.USER_URL}/${userId}/reset_password`
  try {
    const { data } = await axios.post(url, {})
    return data.data.password
  } catch (e) {
    showError(e)
  }
}

interface IUserSettingsRowProps {
  user: IUser
  organizations: IOrganization[]
  fetchAndSetUsers: () => void
  setModalPassword: (password: string) => void
}

export const UserSettingsRow = ({
  user,
  organizations,
  fetchAndSetUsers,
  setModalPassword,
}: IUserSettingsRowProps) => {
  const [editing, setEditing] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [isAdmin, setIsAdmin] = React.useState(user.isAdmin)
  const [email, setEmail] = React.useState(user.email)
  const [organizationId, setOrganizationId] = React.useState(
    user.organization.id,
  )
  const [resetModalUserId, setResetModalUserId] = React.useState<string>('')

  return (
    <Table.Row key={user.id}>
      <Table.Cell>{user.username}</Table.Cell>
      <Table.Cell>
        {!editing ? (
          user.email
        ) : (
          <Input
            value={email}
            placeholder="Email"
            onChange={event => {
              setEmail(event.target.value)
            }}
          />
        )}
      </Table.Cell>
      <Table.Cell>
        {!editing ? (
          user.isAdmin ? (
            'Y'
          ) : (
            'F'
          )
        ) : (
          <Checkbox
            checked={isAdmin}
            onClick={() => {
              setIsAdmin(!isAdmin)
            }}
          />
        )}
      </Table.Cell>
      <Table.Cell>
        {!editing ? (
          user.organization.name
        ) : (
          <Dropdown
            value={organizationId}
            placeholder={'Organization'}
            selection
            options={organizations.map(organization => {
              return {
                key: organization.id,
                text: organization.name,
                value: organization.id,
              }
            })}
            onChange={(event, { value }) => {
              const orgId: string = value as string
              setOrganizationId(orgId)
            }}
          />
        )}
      </Table.Cell>
      <Table.Cell width={1}>
        {editing ? (
          <div className={'edit-user-buttons'}>
            <Button
              loading={loading}
              onClick={async () => {
                setLoading(true)
                const response = await putUser(
                  user.id,
                  isAdmin,
                  organizationId,
                  email,
                )
                if (response !== undefined) {
                  await fetchAndSetUsers()
                  toast.success('User edited')
                }
                setEditing(false)
                setLoading(false)
              }}
            >
              Save
            </Button>
            <Button
              loading={loading}
              onClick={() => {
                setResetModalUserId(user.id)
              }}
            >
              Reset password
            </Button>
            <Button
              loading={loading}
              onClick={async () => {
                setLoading(true)
                await deleteUser(user.id)
                await fetchAndSetUsers()
                toast.success('User deleted.')
                setLoading(false)
              }}
            >
              Remove
            </Button>
            <Button loading={loading} onClick={() => setEditing(false)}>
              Cancel
            </Button>
            <Modal
              closeIcon
              open={resetModalUserId.length > 0}
              onClose={() => {
                setResetModalUserId('')
              }}
            >
              <Header>
                Are you sure you wan't to reset this users password?
              </Header>
              <Modal.Actions>
                <Button color="black" onClick={() => setResetModalUserId('')}>
                  No
                </Button>
                <Button
                  content="Yes"
                  labelPosition="right"
                  icon="checkmark"
                  onClick={async () => {
                    const password = await resetPassword(resetModalUserId)
                    setModalPassword(password)
                    setResetModalUserId('')
                  }}
                  positive
                />
              </Modal.Actions>
            </Modal>
          </div>
        ) : (
          <Button onClick={() => setEditing(true)}>Edit</Button>
        )}
      </Table.Cell>
    </Table.Row>
  )
}
