import {
  Accordion,
  Checkbox,
  Icon,
  List,
  AccordionTitleProps,
  CheckboxProps,
  Loader,
} from 'semantic-ui-react'
import React, { useMemo } from 'react'
import { IColumnInfo, IColumnInfoGroup, IColumnInfoData } from './FilePreview'
import styled from 'styled-components'

type IPushHistory = (search: string) => void

export interface IFilePreviewFiltersProps {
  columnInfo: IColumnInfo
  filterRowsString: string
  filterColumnsString: string
  pushHistory: IPushHistory
  loading: boolean
}

const FilePreviewFilters = ({
  columnInfo,
  filterColumnsString,
  filterRowsString,
  pushHistory,
  loading,
}: IFilePreviewFiltersProps) => {
  const filterColumns = useMemo(
    () => getFilterColumnsOrDefault(filterColumnsString),
    [filterColumnsString],
  )
  const filterRows = useMemo(() => getFilterRowsOrDefault(filterRowsString), [
    filterRowsString,
  ])

  const columnInfoView = useMemo(
    () => getFileInfo(columnInfo, { columns: filterColumns, rows: filterRows }),
    [columnInfo, filterColumns, filterRows],
  )

  const onColumnClickHandler = (
    _: any,
    { active, index: currentColumn }: AccordionTitleProps,
  ) => {
    const rowFilters = getRowFilters({ columnInfoView })
    const columnFilters = getColumnFilters({
      active,
      columnInfoView,
      currentColumn: currentColumn as string,
    })
    const searchQuery = getUriSearch({
      columnFilters,
      rowFilters,
    })
    pushHistory(searchQuery)
  }

  const getOnGroupClickHandler = (currentColumn: string) => (
    _: any,
    { checked: checkboxChecked, name: currentGroup }: CheckboxProps,
  ) => {
    const rowFilters = getRowFilters({
      checkboxChecked,
      columnInfoView,
      currentColumn,
      currentGroup,
    })
    const columnFilters = getColumnFilters({ columnInfoView })
    const searchQuery = getUriSearch({
      columnFilters,
      rowFilters,
    })
    pushHistory(searchQuery)
  }

  return (
    <Accordion className={'styled fluid'}>
      <Accordion.Title active>
        <Icon name="filter" />
        Filters
        <LoaderStyled active={loading} size="small" inline />
      </Accordion.Title>

      {columnInfoView.map(
        ({ checked, groups, original, slugified }: IColumnInfoView) => {
          return (
            <div key={slugified}>
              <Accordion.Title
                active={checked}
                index={slugified}
                onClick={onColumnClickHandler}
              >
                <Icon name={checked ? 'eye' : 'eye slash'} />
                <span>{original}</span>
              </Accordion.Title>
              {!!(groups && groups.length) && (
                <Accordion.Content active>
                  <List>
                    {groups.map(
                      ({
                        _id,
                        checked: itemChecked,
                        label,
                      }: IColumnInfoGroupView) => {
                        const onGroupClickHandler = getOnGroupClickHandler(
                          slugified,
                        )
                        return (
                          <List.Item key={_id}>
                            <Checkbox
                              checked={itemChecked}
                              onChange={onGroupClickHandler}
                              label={label}
                              name={_id}
                            />
                          </List.Item>
                        )
                      },
                    )}
                  </List>
                </Accordion.Content>
              )}
            </div>
          )
        },
      )}
    </Accordion>
  )
}

const LoaderStyled = styled(Loader)`
  &&& {
    margin-left: 10px;
  }
`

interface IStringMap {
  [key: string]: string[]
}

interface IFilters {
  columns: string[]
  rows: IStringMap
}

interface IColumnInfoGroupView {
  _id: string
  label: string
  checked: boolean
}

interface IColumnInfoView {
  checked: boolean
  slugified: string
  original: string
  groups: IColumnInfoGroupView[]
}

const getFileInfo = (
  { data, meta: { total_count } }: IColumnInfo,
  filters: IFilters,
): IColumnInfoView[] => {
  return data.map(({ groups, slugified, original }: IColumnInfoData) => {
    const rowFilters = filters.rows[slugified] || []
    return {
      checked: !filters.columns.includes(slugified),
      groups:
        groups && groups.length > 0
          ? groups.map(({ _id, count }: IColumnInfoGroup) => ({
              _id,
              checked: rowFilters.includes(_id),
              label: `${_id} - ${((100 * count) / total_count).toFixed(2)}%`,
            }))
          : [],
      original,
      slugified,
    }
  })
}

interface IGetRowFiltersArgs {
  columnInfoView: IColumnInfoView[]
  currentColumn?: string
  currentGroup?: string
  checkboxChecked?: boolean
}

const getRowFilters = ({
  columnInfoView,
  currentColumn,
  currentGroup,
  checkboxChecked,
}: IGetRowFiltersArgs) => {
  const columnsApplyFilter = (
    { _id, checked }: IColumnInfoGroupView,
    slugified: string,
  ) => {
    if (currentColumn && currentGroup) {
      return currentColumn === slugified && currentGroup === _id
        ? !!checkboxChecked
        : checked
    } else {
      return checked
    }
  }

  const rowFilters = columnInfoView
    .filter(({ groups, slugified }: IColumnInfoView) =>
      groups.some((group: IColumnInfoGroupView) =>
        columnsApplyFilter(group, slugified),
      ),
    )
    .map(({ slugified, groups }: IColumnInfoView) => ({
      filters: groups
        .filter((group: IColumnInfoGroupView) =>
          columnsApplyFilter(group, slugified),
        )
        .map(({ _id }: IColumnInfoGroupView) => _id)
        .sort(),
      slugified,
    }))
    .sort(rowFilterComparator)
    .map(
      ({ slugified, filters }: IRowFilter) =>
        `${slugified}:${filters.join(',')}`,
    )
    .join(';')

  return rowFilters
}

interface IGetColumnFiltersArgs {
  columnInfoView: IColumnInfoView[]
  currentColumn?: string
  active?: boolean
}

const getColumnFilters = ({
  columnInfoView,
  currentColumn,
  active,
}: IGetColumnFiltersArgs) => {
  const columnsApplyFilter = ({ slugified, checked }: IColumnInfoView) => {
    if (currentColumn) {
      return slugified === currentColumn ? !!active : !checked
    } else {
      return !checked
    }
  }

  const columnFilters = columnInfoView
    .filter((item: IColumnInfoView) => columnsApplyFilter(item))
    .map(({ slugified }) => slugified)
    .join(';')

  return columnFilters
}

export const getFilterRowsOrDefault = (queryRows: string): IStringMap => {
  let rows: IStringMap
  try {
    rows = queryRows
      .split(';')
      .filter(Boolean)
      .reduce((acc, val) => {
        const [key, values] = val.split(':')
        return { ...acc, [key]: values.split(',') }
      }, {})
  } catch {
    rows = {}
  }
  return rows
}

export const getFilterColumnsOrDefault = (queryCols: string): string[] => {
  let columns: string[]
  try {
    columns = queryCols.split(';').filter(Boolean)
  } catch {
    columns = []
  }
  return columns
}

interface IRowFilter {
  slugified: string
  filters: string[]
}

const rowFilterComparator = (
  { slugified: a }: IRowFilter,
  { slugified: b }: IRowFilter,
) => {
  if (a > b) {
    return 1
  }
  if (a < b) {
    return -1
  }
  return 0
}

interface IGetUrlArgs {
  columnFilters: string
  rowFilters: string
}

const getUriSearch = ({ rowFilters, columnFilters }: IGetUrlArgs) => {
  const rowFilterString = rowFilters
    ? `rows=${encodeURIComponent(rowFilters)}`
    : ''
  const columnFilterString = columnFilters ? `cols=${columnFilters}` : ''
  const filterString = [rowFilterString, columnFilterString]
    .filter(Boolean)
    .join('&')
  return filterString ? `?${encodeURIComponent(filterString)}` : ''
}

export default FilePreviewFilters
