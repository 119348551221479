import React, { useState } from 'react'
import { Table, Button, Input } from 'semantic-ui-react'
import { showError } from './FilePreview'
import config from '../config'
import axios, { AxiosError } from 'axios'
import { toast } from 'react-toastify'
import { IBlacklistWord } from '../routes/Blacklist'
import AuthContext from '../AuthContext'

interface IBlacklistRowProps {
  fetchData: () => void
  blacklistword: IBlacklistWord
}

const options = {
  autoClose: 1500,
}

export const handleError = (e: AxiosError) => {
  if (e.response && e.response.status === 409) {
    return toast.warn('duplicate value!', options)
  } else {
    showError(e)
  }
}

const BlacklistRow = ({ fetchData, blacklistword }: IBlacklistRowProps) => {
  const [editInput, setEditInput] = useState('')
  const [editing, setEditing] = useState<boolean>(false)
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false)
  const [editLoading, setEditLoading] = useState<boolean>(false)

  const handleEnterEdit = (e: any, id: string, word: string) => {
    if (e.key === 'Enter') {
      setEditLoading(true)
      e.preventDefault()
      e.stopPropagation()
      handleEdit(id, word)
    }
  }

  const handleEdit = async (id: string, word: string) => {
    if (word.length < 1) {
      return toast.warn('No empty words allowed!', options)
    }
    const url = `${config.BLACKLIST_URL}/${id}`
    try {
      await axios.put(url, {
        id,
        word,
      })
      await fetchData()
      setEditing(false)
    } catch (e) {
      handleError(e)
    }
    setEditLoading(false)
  }

  const handleSetEditing = async (id: string, word: string) => {
    setEditing(true)
    setEditInput(word)
  }

  const handleCancelEditing = () => {
    setEditing(false)
    setEditInput('')
  }

  const handleDelete = async (id: string) => {
    const url = `${config.BLACKLIST_URL}/${id}`
    try {
      await axios.delete(url)
      await fetchData()
    } catch (e) {
      showError(e)
    }
    setDeleteLoading(false)
  }

  const handleEditInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditInput(e.target.value)
  }
  const onKeyDownHandler = (e: any) => {
    handleEnterEdit(e, blacklistword.id, editInput)
  }

  const handleEditHandler = () => {
    setEditLoading(true)
    handleEdit(blacklistword.id, editInput)
  }

  const handleSetEditingHandler = () => {
    handleSetEditing(blacklistword.id, blacklistword.word)
  }

  const handleDeleteHandler = () => {
    setDeleteLoading(true)
    handleDelete(blacklistword.id)
  }

  return editing === true ? (
    <Table.Row className="blacklist-row">
      <Table.Cell className="blacklist-table-first-cell">
        <Input
          focus
          value={editInput}
          onKeyDown={onKeyDownHandler}
          onChange={handleEditInput}
        />
      </Table.Cell>
      <Table.Cell className="blacklist-table-second-cell" floated="right">
        <Button floated="right" type="button" onClick={handleCancelEditing}>
          Cancel
        </Button>
        <Button
          loading={editLoading}
          floated="right"
          type="submit"
          onClick={handleEditHandler}
          color="green"
        >
          Save
        </Button>
      </Table.Cell>
    </Table.Row>
  ) : (
    <Table.Row className="blacklist-row">
      <Table.Cell className="blacklist-table-first-cell">
        {blacklistword.word}
      </Table.Cell>
      <Table.Cell className="blacklist-table-second-cell" floated="right">
        <Button
          loading={deleteLoading}
          floated="right"
          type="button"
          onClick={handleDeleteHandler}
        >
          Remove
        </Button>
        <Button floated="right" type="button" onClick={handleSetEditingHandler}>
          Edit
        </Button>
      </Table.Cell>
    </Table.Row>
  )
}

export default BlacklistRow
