import React, { useCallback } from 'react'
import {
  Button,
  Header,
  Icon,
  Modal,
  Table,
  Dropdown,
  Loader,
} from 'semantic-ui-react'
import config from '../config'
import { getFilterStrings, showError } from './FilePreview'
import axios from 'axios'
import { toast } from 'react-toastify'

interface IFileStat {
  word: string
  count: number
  document_count: number
}

interface IFileStatModalProps {
  fileId: string
  search: string
}

const FileStatModal = ({ fileId, search }: IFileStatModalProps) => {
  const [fileStats, setFileStats] = React.useState<IFileStat[] | undefined>(
    undefined,
  )
  const [loading, setLoading] = React.useState(false)

  const fetchFileStats = useCallback(async () => {
    setLoading(true)
    setFileStats([])
    const url = `${config.FILE_CONTENT_URL}/${fileId}/statistics`
    const { filterColumnsString, filterRowsString } = getFilterStrings(search)
    try {
      const { data } = await axios.get(url, {
        params: { id: fileId, filter_columns: filterColumnsString },
      })
      setFileStats(data.data)
      setLoading(false)
    } catch (e) {
      showError(e)
    }
  }, [fileId, search])

  return (
    <Modal
      trigger={
        <Dropdown.Item
          onClick={() => {
            fetchFileStats()
          }}
        >
          <Icon name={'info'} />
          File Stats
        </Dropdown.Item>
      }
      open={fileStats !== undefined}
      className={'file-stat-modal'}
      closeIcon
      onClose={() => {
        setFileStats(undefined)
      }}
    >
      <Header>File statistics</Header>
      <Modal.Content>
        {loading && <Loader active inline className={'fix-modal-spinner'} />}
        {fileStats && fileStats.length > 0 && (
          <Table>
            <Table.Header>
              <Table.HeaderCell>Word</Table.HeaderCell>
              <Table.HeaderCell>Frequency</Table.HeaderCell>
              <Table.HeaderCell>Present In # Of Documents</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {fileStats.map(stat => {
                return (
                  <Table.Row>
                    <Table.Cell>{stat.word}</Table.Cell>
                    <Table.Cell>{stat.count}</Table.Cell>
                    <Table.Cell>{stat.document_count}</Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            setFileStats(undefined)
          }}
        >
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default FileStatModal
