import React, { Dispatch, SetStateAction } from 'react'
import { IProject } from './components/ProjectSettings'
import config from './config'
import axios from 'axios'
import { showError } from './components/FilePreview'

interface IProjectContextData {
  projectId: string
  setProjectId: React.Dispatch<React.SetStateAction<string>>
  projects: IProject[]
  setProjects: React.Dispatch<React.SetStateAction<IProject[]>>
}

export const fetchProjects = async (
  setProjects: Dispatch<SetStateAction<IProject[]>>,
) => {
  const url = `${config.PROJECT_URL}`
  try {
    const { data } = await axios.get(url)
    setProjects(data.data)
  } catch (e) {
    showError(e)
  }
}

export const ProjectContext = React.createContext<IProjectContextData>({
  projectId: '',
  projects: [],
  setProjectId: () => {},
  setProjects: () => {},
})

export const ProjectProvider = (props: any) => {
  const [projectId, setProjectId] = React.useState<string>('')
  const [projects, setProjects] = React.useState<IProject[]>([])

  React.useEffect(() => {
    fetchProjects(setProjects)
  }, [])

  const value: IProjectContextData = {
    projectId,
    projects,
    setProjectId,
    setProjects,
  }

  return <ProjectContext.Provider value={value} {...props} />
}
