import React, { useState } from 'react'
import axios from 'axios'
import {
  Header,
  Button,
  Icon,
  Input,
  InputOnChangeData,
  Form,
  ButtonProps,
} from 'semantic-ui-react'
import config from '../config'
import styled from 'styled-components'
import { showError } from './FilePreview'
import AuthContext from '../AuthContext'

interface IEditableTopicNameProps {
  name: string
  id: string
  reloadData: () => void
}

const FormStyled = styled(Form)`
  float: left;
`

const HeaderStyled = styled(Header)`
  /* this boosts specificity */
  &&& {
    margin: 0;
  }
  float: left;
`

const ButtonCancelWrapper = styled(Button)`
  &&& {
    margin-left: 0.2em;
  }
`

const ContainerCentered = styled.div`
  display: flex;
  align-items: center;
`

const ButtonCancel = (props: ButtonProps) => (
  <ButtonCancelWrapper
    icon="times"
    circular
    color="red"
    size="tiny"
    {...props}
  />
)

const ButtonEditWrapper = styled(Button)`
  /* align header and input */
  &&& {
    margin-left: 1em;
    margin-top: 3.5px;
    margin-bottom: 3.5px;
  }
`

const ButtonEdit = (props: ButtonProps) => (
  <ButtonEditWrapper icon="edit" size="tiny" color="grey" circular {...props} />
)

const EditableTopicName = ({
  name: defaultName,
  id,
  reloadData,
}: IEditableTopicNameProps) => {
  const [isEditActive, setIsEditActive] = useState<boolean>(false)
  const [name, setName] = useState<string>(defaultName)
  const [loading, setLoading] = useState(false)

  const onEditClickHandler = () => setIsEditActive(true)
  const onChangeHandler = (_: any, { value }: InputOnChangeData) => {
    setName(value)
  }

  const onSubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    setLoading(true)
    const url = `${config.TOPICS}/${id}`
    e.preventDefault()
    try {
      await axios.put(url, { name })
      await reloadData()
    } catch (e) {
      showError(e)
      setName(defaultName)
    }
    setIsEditActive(false)
    setLoading(false)
  }

  const onCancelHandler = () => {
    setName(defaultName)
    setIsEditActive(false)
  }

  return (
    <ContainerCentered>
      {isEditActive ? (
        <>
          <FormStyled onSubmit={onSubmitHandler}>
            <Input
              value={name}
              onChange={onChangeHandler}
              icon={
                loading ? (
                  <Icon
                    name="spinner"
                    circular
                    inverted
                    link
                    color="green"
                    onClick={onSubmitHandler}
                  />
                ) : (
                  <Icon
                    name="check"
                    circular
                    inverted
                    link
                    color="green"
                    onClick={onSubmitHandler}
                  />
                )
              }
            />
          </FormStyled>
          <ButtonCancel onClick={onCancelHandler} />
        </>
      ) : (
        <>
          <HeaderStyled>{name}</HeaderStyled>
          <ButtonEdit onClick={onEditClickHandler} />
        </>
      )}
    </ContainerCentered>
  )
}

export default EditableTopicName
