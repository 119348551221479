import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import NoMatch from './NoMatch'
import Predict from './Predict'
import Prediction from './Prediction'
import Predictions from './Predictions'
import FileExplorer from './FileExplorer'
import FilePreview from './FilePreview'
import CreateTrainingData from './CreateTrainingData'
import Models from './Models'
import NewModel from './NewModel'
import TopicModel from './TopicModel'
import TopicModels from './TopicModels'
import ComparePredictions from './ComparePredictions'
import Graphs from './Graphs'
import config from '../config'
import SpellCheck from './SpellCheck'
import Blacklist from './Blacklist'
import { Settings } from '../components/Settings'

const { ROUTES: routes } = config

const redirectToHome = () => <Redirect to={routes.files} />

const Routes = () => (
  <Switch>
    <Route exact path="/" render={redirectToHome} />
    <Route path={routes.files} exact component={FileExplorer} />
    <Route path={routes.topic_models} exact component={TopicModels} />
    <Route
      path={`${routes.topic_models}/:modelId`}
      exact
      component={TopicModel}
    />
    <Route path={routes.models} exact component={Models} />
    <Route path={`${routes.models}/new`} exact component={NewModel} />
    <Route path={routes.graphs} exact component={Graphs} />
    <Route path={`${routes.files}/:fileId`} exact component={FilePreview} />
    <Route path={`${routes.files}/:fileId/predict`} exact component={Predict} />
    <Route
      path={`${routes.files}/:fileId/create-training-set`}
      exact
      component={CreateTrainingData}
    />
    <Route path={routes.predictions} exact component={Predictions} />
    <Route
      path={`${routes.predictions}/:predictionId/:fileId?`}
      exact
      component={Prediction}
    />
    <Route
      path={`${routes.predictions}/compare/:predictionId1/:predictionId2`}
      exact
      component={ComparePredictions}
    />
    <Route path={routes.blacklist} exact component={Blacklist} />
    <Route path="/files/:fileId/spell-check" exact component={SpellCheck} />
    <Route path="/settings" exact component={Settings} />
    <Route component={NoMatch} />
  </Switch>
)

export default Routes
