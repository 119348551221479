import axios from 'axios'
import React, { useState, useCallback, useEffect } from 'react'
import { RouteComponentProps } from 'react-router'
import {
  Header,
  Segment,
  Breadcrumb,
  Button,
  Loader,
  Table,
  Checkbox,
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import config from '../config'
import { toast } from 'react-toastify'
import { showError } from '../components/FilePreview'
import { ISentiment } from '../components/TopicModelModal'

interface IPredictProps {
  fileId: string
}

const Predict = ({
  match: {
    params: { fileId },
  },
}: RouteComponentProps<IPredictProps>) => {
  const [sentimentId, setSentimentId] = useState<undefined | string>(undefined)
  const [sentiments, setSentiments] = useState<ISentiment[]>([])
  const [loading, setLoading] = useState(true)

  const setSentimentOptions = async () => {
    const url = `${config.SENTIMENT_URL}`
    const { data } = await axios.get(url)
    setSentiments(data)
    setLoading(false)
  }

  useEffect(() => {
    setSentimentOptions()
  }, [])

  const startPrediction = useCallback(async () => {
    const url = `${config.PREDICTIONS_URL}`
    try {
      await axios.post(url, {
        file_id: fileId,
        sentiment_id: sentimentId,
      })
      toast.success(
        'Prediction creation started. Predictions can be seen on the Predictions tab',
      )
      setSentimentId(undefined)
    } catch (e) {
      showError(e)
      setSentimentId(undefined)
    }
  }, [fileId, sentimentId])

  return (
    <div>
      <Breadcrumb size={'large'}>
        <Breadcrumb.Section link as={Link} to={config.ROUTES.files}>
          Files
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon={'right angle'} />
        <Breadcrumb.Section
          link
          as={Link}
          to={`${config.ROUTES.files}/${fileId}`}
        >
          {fileId}
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon={'right angle'} />
        <Breadcrumb.Section>Predict</Breadcrumb.Section>
      </Breadcrumb>
      <Segment>
        <Header>Choose the model the prediction will be based on</Header>
        {loading ? (
          <Loader active inline />
        ) : (
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Selected</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {sentiments.map(sentiment => {
                return (
                  <Table.Row key={sentiment.id}>
                    <Table.Cell>{sentiment.name}</Table.Cell>
                    <Table.Cell>
                      <Checkbox
                        checked={sentiment.id === sentimentId}
                        onClick={() => {
                          if (sentimentId === sentiment.id) {
                            setSentimentId(undefined)
                          } else {
                            setSentimentId(sentiment.id)
                          }
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table>
        )}
        <Button
          color={'green'}
          disabled={sentimentId === undefined}
          onClick={startPrediction}
        >
          Start prediction
        </Button>
      </Segment>
    </div>
  )
}

export default Predict
