import React from 'react'
import { Button, Input } from 'semantic-ui-react'
import { toast } from 'react-toastify'
import AuthContext from '../AuthContext'
import config from '../config'
import axios from 'axios'
import { showError } from './FilePreview'

const changePassword = async (
  userId: string,
  oldPass: string,
  newPass: string,
) => {
  const url = `${config.USER_URL}/${userId}/change_password`
  try {
    const { data } = await axios.post(url, {
      new_password: newPass,
      old_password: oldPass,
    })
    return data
  } catch (e) {
    showError(e)
  }
}

export const ChangePasswordSettings = () => {
  const [oldPassword, setOldPassword] = React.useState<string>('')
  const [newPassword, setNewPassword] = React.useState<string>('')
  const [newPassword2, setNewPassword2] = React.useState<string>('')
  const { jwtContent } = React.useContext(AuthContext)

  const onButtonPress = async () => {
    if (newPassword !== newPassword2) {
      toast.warn('New passwords do not match')
      return
    }
    const response = await changePassword(
      jwtContent!.userId,
      oldPassword,
      newPassword,
    )
    if (response !== undefined) {
      setOldPassword('')
      setNewPassword('')
      setNewPassword2('')
      toast.success('Password changed')
    }
  }

  return (
    <div className={'sub-settings'}>
      <h2>Change password</h2>
      <Input
        value={oldPassword}
        type={'password'}
        placeholder="Current password"
        onChange={event => {
          setOldPassword(event.target.value)
        }}
      />
      <Input
        value={newPassword}
        type={'password'}
        placeholder="New password"
        onChange={event => {
          setNewPassword(event.target.value)
        }}
      />
      <Input
        value={newPassword2}
        type={'password'}
        placeholder="New password again"
        onChange={event => {
          setNewPassword2(event.target.value)
        }}
      />
      <Button onClick={onButtonPress}>Change</Button>
    </div>
  )
}
