import axios from 'axios'
import React from 'react'
import config from '../config'
import {
  Breadcrumb,
  Button,
  Checkbox,
  Icon,
  Label,
  List,
  Table,
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { ITrainingFile } from './NewModel'
import moment from 'moment'
import PaginatedTable, {
  IPaginatedQueryResult,
} from '../components/PaginatedTable'

interface IModel {
  name: string
  status: string
  model_filename: string
  created_at: string
  id: string
  training_files: ITrainingFile[]
}

interface IModelsTableProps {
  haveChoice?: boolean
  selectedId?: string
  onChooseSelect?: (modelId: string) => void
}

export const getStatusElement = (status: string) => {
  switch (status) {
    case 'deleting':
      return <Label color={'orange'}>Deleting</Label>
    case 'done':
      return <Label color={'green'}>Done</Label>
    case 'created':
      return <Label color={'blue'}>Created</Label>
    case 'started':
      return <Label color={'teal'}>Started</Label>
    case 'failed':
      return <Label color={'red'}>Failed</Label>
    case 'SUCCESS':
      return <Label color={'green'}>Done</Label>
    case 'FAILURE':
      return <Label color={'red'}>Failed</Label>
    case 'STARTED':
      return <Label color={'teal'}>Started</Label>
    case 'PENDING':
      return <Label color={'blue'}>Created</Label>
    default:
      return <span>{status}</span>
  }
}

const getData = async (
  page: number,
): Promise<IPaginatedQueryResult<IModel>> => {
  const url = `${config.MODELS_URL}`
  const { data } = await axios.get(url, {
    params: { page },
  })
  return data
}

export const ModelsTable = ({
  haveChoice,
  selectedId,
  onChooseSelect,
}: IModelsTableProps) => {
  const rowElementGetter = (item: IModel) => {
    const onClickHandler = () => {
      if (onChooseSelect !== undefined) {
        onChooseSelect!(item.id)
      }
    }
    return (
      <Table.Row key={item.id}>
        {haveChoice && (
          <Table.Cell>
            {item.status === 'done' && (
              <Checkbox
                radio
                label={'Select'}
                checked={item.id === selectedId}
                onClick={onClickHandler}
              />
            )}
          </Table.Cell>
        )}
        <Table.Cell>{item.name}</Table.Cell>
        <Table.Cell>{item.model_filename}</Table.Cell>
        <Table.Cell>
          <List>
            {item.training_files.map(trainingFile => {
              return (
                <List.Item key={trainingFile.id}>
                  {trainingFile.document_name} ({trainingFile.name})
                </List.Item>
              )
            })}
          </List>
        </Table.Cell>
        <Table.Cell>
          {moment(item.created_at).format('DD.MM.Y HH:mm:ss')}
        </Table.Cell>
        <Table.Cell>{getStatusElement(item.status)}</Table.Cell>
      </Table.Row>
    )
  }

  return (
    <PaginatedTable
      dataGetter={getData}
      headerRow={
        <Table.Row>
          {haveChoice && <Table.HeaderCell>Select</Table.HeaderCell>}
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>File Name</Table.HeaderCell>
          <Table.HeaderCell>Base Files</Table.HeaderCell>
          <Table.HeaderCell>Created At</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
        </Table.Row>
      }
      rowElementGetter={rowElementGetter}
    />
  )
}

const Models = () => {
  return (
    <div>
      <div className={'breadcrumb-container'}>
        <Breadcrumb size={'large'}>
          <Breadcrumb.Section>Models</Breadcrumb.Section>
        </Breadcrumb>
        <Button color={'green'} as={Link} to={`${config.ROUTES.models}/new`}>
          <Icon name={'plus'} />
          New
        </Button>
      </div>
      <ModelsTable />
    </div>
  )
}
export default Models
