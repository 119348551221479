import React, { useCallback, useEffect, useState, useMemo } from 'react'
import axios from 'axios'
import { Breadcrumb, Button, Header, Segment } from 'semantic-ui-react'
import { Link, RouteComponentProps } from 'react-router-dom'
import config from '../config'
import {
  VictoryAxis,
  VictoryChart,
  VictoryScatter,
  VictoryTheme,
} from 'victory'
import {
  IColumnInfo,
  showError,
  getFilterStrings,
  fetchFileInfo,
} from '../components/FilePreview'
import FilterPanel from '../components/FilterPanel'
import FilePreviewFilters from '../components/FilePreviewFilters'
import styled from 'styled-components'
import useDebounce from '../hooks/useDebounce'
import DimmedLoader from '../components/DimmedLoader'
import AuthContext from '../AuthContext'

interface IComparePredictionsProps {
  predictionId1: string
  predictionId2: string
}

interface IPoint {
  x: number
  y: number
  size: number
  color: string
  name: string | undefined
}

interface IComparePredictionValue {
  x: number
  y: number
}

interface IComparePredictionData {
  data: IComparePredictionValue[]
  x_sentiment_name: string
  y_sentiment_name: string
  x_avg: number
  y_avg: number
  file_name: string
  file_id: string
  count: number
}

interface IFetchComparisonArgs {
  rowsFilter: string | undefined
  columnsFilter: string | undefined
  predictionId1: string
  predictionId2: string
}

const fetchComparison = async ({
  rowsFilter,
  columnsFilter,
  predictionId1,
  predictionId2,
}: IFetchComparisonArgs) => {
  const url = `${config.PREDICTIONS_URL}/compare/${predictionId1}/${predictionId2}`
  try {
    const { data } = await axios.get(url, {
      params: {
        columns_filter: columnsFilter,
        rows_filter: rowsFilter,
      },
    })
    return data
  } catch (e) {
    showError(e)
  }
}

const defaultColumnInfo: IColumnInfo = {
  data: [],
  meta: {
    total_count: -1,
  },
}

const tickFormatter = (thisValue: number) => {
  return thisValue * 100
}

const BreadcrumbStyled = styled(Breadcrumb)`
  && {
    margin-bottom: 1em;
  }
`

const SegmentStyled = styled(Segment)`
  height: 500px;
`

const ComparePredictions = ({
  match: {
    params: { predictionId1, predictionId2 },
  },
  history,
  location: { pathname, search },
}: RouteComponentProps<IComparePredictionsProps>) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [predictionData, setPredictionData] = useState<
    IComparePredictionData | undefined
  >(undefined)

  const [pointData, setPointData] = useState<IPoint[]>([])
  const [savedPointData, setSavedPointData] = useState<IPoint[]>([])

  const [columnInfoLoading, setColumnInfoLoading] = useState(true)
  const [columnInfo, setColumnInfo] = useState<IColumnInfo>(defaultColumnInfo)
  const [totalCount, setTotalCount] = useState<number>(-1)

  const { filterRowsString, filterColumnsString } = useMemo(
    () => getFilterStrings(search),
    [search],
  )

  const {
    filterRowsString: debouncedFilterRowsString,
    filterColumnsString: debouncedFilterColumnsString,
  } = useDebounce(
    { filterRowsString, filterColumnsString },
    600, // is it good enough?
  )

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        setLoading(true)
        const data = await fetchComparison({
          columnsFilter: debouncedFilterColumnsString,
          predictionId1,
          predictionId2,
          rowsFilter: debouncedFilterRowsString,
        })
        if (data !== undefined) {
          setPredictionData(data)
          setPointDataFromFetched(data, data.count, [])
          setTotalCount(data.count)
        }
      } catch (error) {
        showError(error)
      } finally {
        setLoading(false)
      }
    }

    fetchAllData()
  }, [
    debouncedFilterRowsString,
    debouncedFilterColumnsString,
    predictionId1,
    predictionId2,
  ])

  useEffect(() => {
    const fetchInfoHandler = async () => {
      if (!predictionData) {
        return
      }
      try {
        setColumnInfoLoading(true)
        const data = await fetchFileInfo({
          fileId: predictionData.file_id,
        })
        if (data !== undefined) {
          setColumnInfo(data)
        }
      } catch (error) {
        showError(error)
      } finally {
        setColumnInfoLoading(false)
      }
    }
    fetchInfoHandler()
  }, [predictionData])

  const saveCurrentAvg = () => {
    if (predictionData) {
      const newPoint: IPoint = {
        color: '#4A9',
        name: 'My saved',
        size: Math.floor((20 * predictionData.count) / totalCount),
        x: predictionData.x_avg,
        y: predictionData.y_avg,
      }
      const newSaved = [...savedPointData, newPoint]
      setSavedPointData(newSaved)
      setPointDataFromFetched(predictionData, totalCount, newSaved)
    }
  }

  const setPointDataFromFetched = (
    data: IComparePredictionData,
    count: number,
    savedPoints: IPoint[],
  ) => {
    const newData = data.data.map(fetchedPoint => {
      return {
        ...fetchedPoint,
        color: '#666',
        name: undefined,
        size: 3,
      }
    })
    setPointData([
      {
        color: '#438eaa',
        name: undefined,
        size: 20 * (data.count / count),
        x: data.x_avg,
        y: data.y_avg,
      },
      ...newData,
    ])
  }

  const pushHistory = useCallback(
    (searchQuery: string) => {
      const url = `${pathname}${searchQuery}`
      history.push(url)
    },
    [history, pathname],
  )

  const svgSize = 400

  return (
    <>
      <BreadcrumbStyled size={'large'}>
        <Breadcrumb.Section link as={Link} to={'/predictions'}>
          Predictions
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon={'right angle'} />
        <Breadcrumb.Section>Compare</Breadcrumb.Section>
      </BreadcrumbStyled>
      <div className={'filterable-content'}>
        <div className={'filters'}>
          <FilePreviewFilters
            columnInfo={columnInfo}
            filterColumnsString={filterColumnsString || ''}
            filterRowsString={filterRowsString || ''}
            loading={columnInfoLoading}
            pushHistory={pushHistory}
          />
        </div>
        <div className={'filterable-data'}>
          {loading ? (
            <SegmentStyled>
              <DimmedLoader />
            </SegmentStyled>
          ) : (
            <>
              <Header>Showing values</Header>
              <Button onClick={saveCurrentAvg}>Save current AVG</Button>
              <FilterPanel
                columnInfo={columnInfo}
                filterRowsString={debouncedFilterRowsString}
                loading={columnInfoLoading}
              />
              <div className={'compare-prediction-container'}>
                <VictoryChart
                  theme={VictoryTheme.material}
                  domain={{ x: [0, 1], y: [0, 1] }}
                  width={svgSize}
                  height={svgSize}
                >
                  <VictoryScatter
                    style={{
                      data: {
                        fill: (d: { datum: IPoint }) => {
                          return d.datum.color
                        },
                        opacity: 0.5,
                      },
                    }}
                    data={[...pointData, ...savedPointData]}
                  />
                  <VictoryAxis
                    theme={VictoryTheme.material}
                    standalone={false}
                    tickValues={[0, 0.25, 0.75, 1]}
                    tickFormat={tickFormatter}
                    offsetY={svgSize / 2}
                  />
                  <VictoryAxis
                    theme={VictoryTheme.material}
                    dependentAxis
                    tickValues={[0, 0.25, 0.75, 1]}
                    tickFormat={tickFormatter}
                    offsetX={svgSize / 2}
                  />
                  {predictionData !== undefined && (
                    <>
                      <VictoryAxis
                        label={predictionData.x_sentiment_name}
                        axisComponent={<React.Fragment />}
                        gridComponent={<React.Fragment />}
                        tickComponent={<React.Fragment />}
                        tickLabelComponent={<React.Fragment />}
                        standalone={false}
                      />
                      <VictoryAxis
                        dependentAxis
                        label={predictionData.y_sentiment_name}
                        axisComponent={<React.Fragment />}
                        gridComponent={<React.Fragment />}
                        tickComponent={<React.Fragment />}
                        tickLabelComponent={<React.Fragment />}
                      />
                    </>
                  )}
                </VictoryChart>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default ComparePredictions
