import React from 'react'
import axios from 'axios'
import config from '../config'
import PaginatedTable, {
  IPaginatedQueryResult,
} from '../components/PaginatedTable'
import {
  Breadcrumb,
  Button,
  Table,
  Label,
  Icon,
  Confirm,
} from 'semantic-ui-react'
import moment from 'moment'
import { getStatusElement } from './Models'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { IScoreOption } from '../components/TopicModelContent'
import { ProjectContext } from '../ProjectContext'
import Hidden from '../components/Hidden'
import { toast } from 'react-toastify'
import { showError } from '../components/FilePreview'

interface ITerm {
  score: number
  name: string
}

export interface ITopicCellScore {
  cell_id: string
  cell: string
  score: number
}

export interface ISentimentMap {
  [key: string]: number
}

export interface ITopic {
  terms?: ITerm[]
  id: string
  index: number
  name: string
  topic_cell_scores?: ITopicCellScore[]
  score_document_count: number
  score_filtered_document_count: number
  score_td_idf: number
  score_cosine_similarity: number
  sentiments?: ISentimentMap
}

export interface ITopicModelParams {
  number_of_passes: number
  number_of_topics: number
}

export interface ITopicModel {
  topics?: ITopic[]
  document_id: string
  score: number | null
  name: string
  status: string
  id: string
  created_at: string
  document_name: string
  filter_rows: string | null
  filter_columns: string | null
  filter_rows_labels?: string[] | null
  filter_columns_labels?: string[] | null
  params: ITopicModelParams
  sentiment_ids: string[]
  sentiment_values: IScoreOption[]
}

const LabelStyled = styled(Label)`
  && {
    margin-bottom: 5px;
    margin-right: 5px;
  }
`

interface IRowFiltersProps {
  filters: string[] | null | undefined
}

export const RowsFilter = ({ filters = [] }: IRowFiltersProps) => (
  <>
    {!!filters &&
      filters.map((filter: string, index: number) => (
        <LabelStyled color="teal" key={index}>
          {filter}
        </LabelStyled>
      ))}
  </>
)

interface IColumnsFilterProps {
  filters: string[] | null | undefined
}

const ColumnsFilter = ({ filters = [] }: IColumnsFilterProps) => (
  <>
    {!!filters &&
      filters.map((filter: string, index: number) => (
        <LabelStyled color="grey" key={index}>
          <Icon name="eye slash" />
          {filter}
        </LabelStyled>
      ))}
  </>
)

const getLinkHref = (
  documentId: string,
  filterRows: string | null,
  filterColumns: string | null,
) => {
  const rows = (filterRows && `rows=${filterRows}`) || ''
  const cols = (filterColumns && `cols=${filterColumns}`) || ''
  const search = [rows, cols].filter(Boolean).join('&')
  const searchString = (search && `?${encodeURIComponent(search)}`) || ''
  return `${config.ROUTES.files}/${documentId}${searchString}`
}

const TopicModels = () => {
  const { projectId } = React.useContext(ProjectContext)
  const [confirmRemoveId, setConfirmRemoveId] = React.useState<
    string | undefined
  >(undefined)

  const handleDelete = async (id: string) => {
    const url = `${config.TOPIC_MODELS_URL}/${id}`
    try {
      await axios.delete(url)
      toast.success('Topic Model Deleted')
    } catch (e) {
      showError(e)
    }
  }

  const rowElementGetter = ({
    id,
    document_name,
    document_id,
    filter_rows,
    filter_rows_labels,
    filter_columns,
    filter_columns_labels,
    created_at,
    score,
    status,
    name,
    params: { number_of_passes, number_of_topics },
  }: ITopicModel) => {
    return (
      <Table.Row key={id}>
        <Table.Cell>
          <Link to={getLinkHref(document_id, filter_rows, filter_columns)}>
            {document_name}
          </Link>
        </Table.Cell>
        <Table.Cell>{name}</Table.Cell>
        <Table.Cell>
          {((filter_rows_labels && filter_rows_labels.length) ||
            (filter_columns_labels && filter_columns_labels.length)) && (
            <Hidden>
              <RowsFilter filters={filter_rows_labels} />
              <ColumnsFilter filters={filter_columns_labels} />
            </Hidden>
          )}
        </Table.Cell>
        <Table.Cell>
          <LabelStyled color="blue">
            {`Number of topics: ${number_of_topics}`}
          </LabelStyled>
          <LabelStyled color="blue">
            {`Number of passes: ${number_of_passes}`}
          </LabelStyled>
        </Table.Cell>
        <Table.Cell>{moment(created_at).format('DD.MM.Y HH:mm:ss')}</Table.Cell>
        <Table.Cell>{score}</Table.Cell>
        <Table.Cell>{getStatusElement(status)}</Table.Cell>
        <Table.Cell>
          {status === 'done' && (
            <>
              <Button as={Link} to={`${config.ROUTES.topic_models}/${id}`}>
                View
              </Button>
              <Button
                onClick={() => {
                  setConfirmRemoveId(id)
                }}
              >
                Remove
              </Button>
            </>
          )}
        </Table.Cell>
      </Table.Row>
    )
  }

  const getData = async (
    page: number,
  ): Promise<IPaginatedQueryResult<ITopicModel>> => {
    const url = `${config.TOPIC_MODELS_URL}`
    const { data } = await axios.get(url, {
      params: { page, project_id: projectId },
    })
    return data
  }

  return (
    <React.Fragment>
      <Confirm
        open={confirmRemoveId !== undefined}
        onCancel={() => {
          setConfirmRemoveId(undefined)
        }}
        onConfirm={() => {
          if (confirmRemoveId !== undefined) {
            handleDelete(confirmRemoveId)
          }
          setConfirmRemoveId(undefined)
        }}
      />
      <div className={'breadcrumb-container'}>
        <Breadcrumb size={'large'}>
          <Breadcrumb.Section>Topic Models</Breadcrumb.Section>
        </Breadcrumb>
        <Button color={'green'} as={Link} to={config.ROUTES.files}>
          <Icon name={'plus'} />
          New
        </Button>
      </div>
      <PaginatedTable
        celled
        striped
        headerRow={
          <Table.Row>
            <Table.HeaderCell>Document Name</Table.HeaderCell>
            <Table.HeaderCell>Topic Model Name</Table.HeaderCell>
            <Table.HeaderCell width={6}>Filters</Table.HeaderCell>
            <Table.HeaderCell width={2}>Parameters</Table.HeaderCell>
            <Table.HeaderCell>Created At</Table.HeaderCell>
            <Table.HeaderCell>Model Coherence</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        }
        rowElementGetter={rowElementGetter}
        dataGetter={getData}
      />
    </React.Fragment>
  )
}

export default TopicModels
