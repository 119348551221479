import React, { useState, useCallback, useEffect } from 'react'
import {
  Modal,
  Button,
  Icon,
  Header,
  Input,
  InputOnChangeData,
  Checkbox,
  Loader,
  Dropdown,
} from 'semantic-ui-react'
import { ITopicModelParams } from '../routes/FilePreview'
import './TopicModelModal.scss'
import config from '../config'
import axios from 'axios'
import { IKeyMap } from './TopicModelDownloadXlsxModal'

const DEFAULT_NUMBER_OF_PASSES = 5
const DEFAULT_NUMBER_OF_TOPICS = 20

type IOnSubmit = (params: ITopicModelParams) => Promise<boolean>

interface ITopicModelModalProps {
  onSubmit: IOnSubmit
}

export interface ISentiment {
  id: string
  name: string
}

const TopicModelModal = ({ onSubmit }: ITopicModelModalProps) => {
  const [open, setOpen] = useState(false)
  const [passes, setPasses] = useState(DEFAULT_NUMBER_OF_PASSES)
  const [topics, setTopics] = useState(DEFAULT_NUMBER_OF_TOPICS)
  const [name, setName] = useState('')

  const [selectedSentimentIds, setSelectedSentimentIds] = useState<IKeyMap>({})
  const [sentimentsLoading, setSentimentsLoading] = useState(true)
  const [sentiments, setSentiments] = useState<ISentiment[]>([])

  const onPassesChange = useCallback((_: any, { value }: InputOnChangeData) => {
    setPasses(Number(value))
  }, [])

  const onTopicsChange = useCallback((_: any, { value }: InputOnChangeData) => {
    setTopics(Number(value))
  }, [])

  const resetToDefault = useCallback(() => {
    setPasses(DEFAULT_NUMBER_OF_PASSES)
    setTopics(DEFAULT_NUMBER_OF_TOPICS)
  }, [])

  const closeModal = useCallback(() => {
    setOpen(false)
    resetToDefault()
  }, [resetToDefault])

  const setSentimentOptions = async () => {
    const url = `${config.SENTIMENT_URL}`
    const { data } = await axios.get(url)
    setSentiments(data)
    setSentimentsLoading(false)
  }

  useEffect(() => {
    setSentimentOptions()
  }, [])

  const openModal = useCallback(() => setOpen(true), [])
  const onSubmitHandler = async () => {
    const selectedIds = Object.entries(selectedSentimentIds)
      .filter(a => a[1])
      .map(a => a[0])

    const succeeded = await onSubmit({
      name,
      passes,
      selectedSentimentIds: selectedIds,
      topics,
    })
    if (succeeded) {
      closeModal()
      resetToDefault()
    }
  }

  return (
    <Modal
      trigger={
        <Dropdown.Item color={'green'} onClick={openModal}>
          <Icon name={'plus'} />
          New topic model
        </Dropdown.Item>
      }
      open={open}
      onClose={closeModal}
      closeIcon
      className={'topic-model-modal'}
    >
      <Header icon="cogs" content="Topic Model Parameters" />
      <Modal.Content>
        <p>Specify parameters for the topic modelling.</p>
        <Input
          label="Number of topics"
          placeholder="topics..."
          type="number"
          value={topics}
          onChange={onTopicsChange}
        />
        <br />
        <br />
        <Input
          label="Number of passes"
          placeholder="passes"
          type="number"
          value={passes}
          onChange={onPassesChange}
        />
        <br />
        <br />
        <Input
          label="Name"
          placeholder="Name"
          // type="string"
          value={name}
          onChange={event => {
            setName(event.target.value)
          }}
        />
        <h3>Sentiments:</h3>
        {sentimentsLoading && <Loader active inline />}
        <div className={'sentiment-choices'}>
          {sentiments.map(sentiment => {
            return (
              <Checkbox
                key={sentiment.id}
                checked={selectedSentimentIds[sentiment.id]}
                label={sentiment.name}
                onClick={() => {
                  const key = sentiment.id
                  const newVal = !selectedSentimentIds[key]
                  const newState: IKeyMap = { ...selectedSentimentIds }
                  newState[key] = newVal
                  setSelectedSentimentIds(newState)
                }}
              />
            )
          })}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button color="green" onClick={onSubmitHandler}>
          <Icon name="checkmark" />
          Create
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default TopicModelModal
