import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import { ProjectContext } from '../ProjectContext'

export const ProjectChooser = () => {
  const { projectId, setProjectId, projects } = React.useContext(ProjectContext)

  return (
    <div className={'project-chooser'}>
      <Dropdown
        clearable
        value={projectId}
        placeholder={'All Projects'}
        selection
        options={projects.map(project => {
          return {
            key: project.id,
            text: project.name,
            value: project.id,
          }
        })}
        onChange={(event, { value }) => {
          const newProjectId = value as string
          setProjectId(newProjectId)
        }}
      />
    </div>
  )
}
