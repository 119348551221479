import React, { useCallback, useState } from 'react'
import axios from 'axios'
import config from '../config'
import {
  Checkbox,
  Table,
  Progress,
  Label,
  Button,
  Breadcrumb,
  Icon,
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import PaginatedTable, {
  IPaginatedQueryResult,
} from '../components/PaginatedTable'
import { getStatusElement } from './Models'

export interface IPrediction {
  id: string
  sentiment_id: string | undefined
  sentiment_name: string | undefined
  status: string
  file_id: string
  progress: number
  created_at: string
  document_name: string
  document_id: string
}

interface IPredictionListingItem {
  id: string
  sentiment_name: string
  organization_id: string
  document_name: string
  document_id: string
  sentiment_id: string
  created_at: string
  status: string
  max_created_at: string
}

const fetchData = async (
  page: number,
): Promise<IPaginatedQueryResult<IPredictionListingItem>> => {
  const url = `${config.PREDICTIONS_URL}`
  const { data } = await axios.get(url, {
    params: { page },
  })
  return data
}

type IGetCompareElement = (
  prediction: IPredictionListingItem,
  selected: IPredictionListingItem | undefined,
) => JSX.Element

const rowElementGetter = (
  selectedPrediction: IPredictionListingItem | undefined,
  getCompareElement: IGetCompareElement,
) => (prediction: IPredictionListingItem) => {
  const {
    id,
    sentiment_name,
    document_name,
    document_id,
    created_at,
    status,
  } = prediction
  return (
    <Table.Row key={id}>
      <Table.Cell>
        <Link to={`/files/${document_id}`}>{document_name}</Link>
      </Table.Cell>
      <Table.Cell>{sentiment_name && sentiment_name}</Table.Cell>
      <Table.Cell>{moment(created_at).format('DD.MM.Y HH:mm:ss')}</Table.Cell>
      <Table.Cell>{getStatusElement(prediction.status)}</Table.Cell>
      <Table.Cell>
        {getCompareElement(prediction, selectedPrediction)}
      </Table.Cell>
      <Table.Cell>
        {status === 'done' && (
          <Link to={`${config.ROUTES.predictions}/${id}/${document_id}`}>
            <Button>View</Button>
          </Link>
        )}
      </Table.Cell>
    </Table.Row>
  )
}

const Predictions = () => {
  const [selectedPrediction, setSelectedPrediction] = useState<
    IPredictionListingItem | undefined
  >(undefined)

  const getCompareElement = useCallback(
    (
      prediction: IPredictionListingItem,
      selected: IPredictionListingItem | undefined,
    ) => {
      if (selected === undefined) {
        if (prediction.status === 'done') {
          const onClickHandler = () => setSelectedPrediction(prediction)
          return <Checkbox onClick={onClickHandler} />
        }
      } else {
        if (prediction.id === selected!.id) {
          const onClickHandler = () => setSelectedPrediction(undefined)
          return <Checkbox onClick={onClickHandler} />
        } else if (
          selected!.document_id === prediction.document_id &&
          prediction.status === 'done'
        ) {
          return (
            <Link to={`/predictions/compare/${selected!.id}/${prediction.id}`}>
              <Button>Compare</Button>
            </Link>
          )
        }
      }
      return <React.Fragment />
    },
    [],
  )

  return (
    <div>
      <div className={'breadcrumb-container'}>
        <Breadcrumb size={'large'}>
          <Breadcrumb.Section>Predictions</Breadcrumb.Section>
        </Breadcrumb>
        <Button color={'green'} as={Link} to={'/files'}>
          <Icon name={'plus'} />
          New
        </Button>
      </div>
      <PaginatedTable
        dataGetter={fetchData}
        headerRow={
          <Table.Row>
            <Table.HeaderCell>File</Table.HeaderCell>
            <Table.HeaderCell>Model</Table.HeaderCell>
            <Table.HeaderCell>Created At</Table.HeaderCell>
            <Table.HeaderCell className={'status-cell'}>
              Status
            </Table.HeaderCell>
            <Table.HeaderCell>Compare</Table.HeaderCell>
            <Table.HeaderCell>View</Table.HeaderCell>
          </Table.Row>
        }
        rowElementGetter={rowElementGetter(
          selectedPrediction,
          getCompareElement,
        )}
      />
    </div>
  )
}

export default Predictions
