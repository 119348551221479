import React, { useState } from 'react'
import { RouteComponentProps } from 'react-router'
import TopicModelContent from '../components/TopicModelContent'
import { Dropdown } from 'semantic-ui-react'

interface ITopicModelProps {
  modelId: string
}

const TopicModel = ({ match }: RouteComponentProps<ITopicModelProps>) => {
  const { modelId } = match.params
  const [threshold, setThreshold] = useState(0.7)
  return (
    <div>
      <div className={'threshold-controls'}>
        <span>Threshold: </span>
        <Dropdown
          value={threshold}
          onChange={(_, dropdownProps) => {
            setThreshold(dropdownProps.value as number)
          }}
          options={[1, 5, 10, 20, 30, 40, 50, 60, 70, 80, 90].map(
            percentile => {
              return {
                text: `${percentile}%`,
                value: percentile / 100,
              }
            },
          )}
        />
      </div>
      <TopicModelContent
        modelId={modelId}
        key={modelId}
        threshold={threshold}
      />
    </div>
  )
}

export default TopicModel
