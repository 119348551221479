import React, { useState, ChangeEvent } from 'react'
import axios from 'axios'
import {
  Button,
  Breadcrumb,
  Table,
  Loader,
  Confirm,
  Dropdown,
} from 'semantic-ui-react'
import config from '../config'
import { showError } from '../components/FilePreview'
import PaginatedTable, {
  IPaginatedQueryResult,
} from '../components/PaginatedTable'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import moment from 'moment'
import { ProjectContext } from '../ProjectContext'
import AuthContext from '../AuthContext'
import { getStatusElement } from './Models'
import './FileExplorer.scss'

const getData = async (page: number): Promise<IPaginatedQueryResult<IFile>> => {
  const url = `${config.FILE_URL}`
  const { data } = await axios.get(url, {
    params: { page },
  })
  return data
}

const uploadFile = async (fileContent: File) => {
  const url = `${config.FILE_URL}/`
  try {
    const formData = new FormData()
    formData.append('file', fileContent)

    const { data } = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return data
  } catch (e) {
    showError(e)
  }
}

interface IOrganization {
  id: string
  name: string
}

interface IFile {
  id: string
  status: string
  name: string
  created_at: string
  organization?: IOrganization
  project_id: string
}

const FileExplorer = () => {
  const [fileContent, setFileContent] = useState<File | undefined>(undefined)
  const [tableUpdateSignla, setTableUpdateSignal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [confirmRemoveId, setConfirmRemoveId] = React.useState<
    string | undefined
  >(undefined)
  const { projects } = React.useContext(ProjectContext)
  const { jwtContent } = React.useContext(AuthContext)
  const [updateIndex, setUpdateIndex] = React.useState(0)

  const putFile = async (fileId: string, projectId: string | undefined) => {
    const url = `${config.FILE_URL}/${fileId}`
    try {
      const { status } = await axios.put(url, { project_id: projectId })
      if (status === 204) {
        return true
      }
    } catch (e) {
      showError(e)
    }
    return false
  }

  const rowElementGetter = ({
    id,
    name,
    organization,
    created_at,
    project_id,
    status,
  }: IFile) => {
    return (
      <Table.Row key={id}>
        <Table.Cell>
          {status === 'done' ? (
            <Link to={`${config.ROUTES.files}/${id}`}>{name}</Link>
          ) : (
            <div className={'name-and-status'}>
              <span>{name}</span>
              <span>{getStatusElement(status)}</span>
            </div>
          )}
        </Table.Cell>
        <Table.Cell>{moment(created_at).format('DD.MM.Y HH:mm:ss')}</Table.Cell>
        <Table.Cell>{organization && organization.name}</Table.Cell>
        <Table.Cell>
          {organization &&
          jwtContent &&
          organization.id === jwtContent.organizationId ? (
            <Dropdown
              clearable
              value={project_id}
              placeholder={'None'}
              selection
              options={projects.map(project => {
                return {
                  key: project.id,
                  text: project.name,
                  value: project.id,
                }
              })}
              onChange={async (event, { value }) => {
                const newProjectId = value as string | undefined
                const putSucceeded = await putFile(
                  id,
                  newProjectId || undefined,
                )
                if (putSucceeded) {
                  toast.success('Files project changed')
                  setUpdateIndex(updateIndex + 1)
                }
              }}
            />
          ) : (
            <></>
          )}
        </Table.Cell>
        <Table.Cell>
          {status === 'done' && (
            <Button
              onClick={() => {
                setConfirmRemoveId(id)
              }}
            >
              Remove
            </Button>
          )}
        </Table.Cell>
      </Table.Row>
    )
  }

  const onUploadHandler = async () => {
    if (fileContent !== undefined) {
      setLoading(true)
      const response = await uploadFile(fileContent)
      if (response !== undefined) {
        setTableUpdateSignal(tableUpdateSignla + 1)
        toast.success('File uploaded.')
      }
      setLoading(false)
      setFileContent(undefined)
    }
  }

  const handleDelete = async (id: string) => {
    const url = `${config.FILE_URL}/${id}`
    setLoadingDelete(true)
    try {
      await axios.delete(url)
      toast.success('File deleted')
    } catch (e) {
      showError(e)
    }
    setLoadingDelete(false)
  }

  const onFileChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const file: File = event.target.files![0]
    setFileContent(file)
  }

  return (
    <React.Fragment>
      <Confirm
        open={confirmRemoveId !== undefined}
        onCancel={() => {
          setConfirmRemoveId(undefined)
        }}
        onConfirm={() => {
          if (confirmRemoveId !== undefined) {
            handleDelete(confirmRemoveId)
          }
          setConfirmRemoveId(undefined)
        }}
      />
      <Breadcrumb size={'large'}>
        <Breadcrumb.Section>Files</Breadcrumb.Section>
      </Breadcrumb>

      <h2>Upload</h2>
      <input
        name="file"
        onChange={onFileChangeHandler}
        type="file"
      />
      <Button
        loading={loading}
        onClick={onUploadHandler}
        disabled={fileContent === undefined}
        color={fileContent ? 'green' : undefined}
      >
        Upload file
      </Button>

      <h2>Files</h2>

      {loadingDelete ? (
        <Loader inline active />
      ) : (
        <PaginatedTable
          key={updateIndex}
          celled
          striped
          updateSignal={tableUpdateSignla}
          headerRow={
            <Table.Row>
              <Table.HeaderCell>File</Table.HeaderCell>
              <Table.HeaderCell>Created At</Table.HeaderCell>
              <Table.HeaderCell>Organization</Table.HeaderCell>
              <Table.HeaderCell>Project</Table.HeaderCell>
              <Table.HeaderCell>Remove</Table.HeaderCell>
            </Table.Row>
          }
          rowElementGetter={rowElementGetter}
          dataGetter={getData}
        />
      )}
    </React.Fragment>
  )
}

export default FileExplorer
