import React from 'react'
import EditableTopicName from './EditableTopicName'
import { ITopic } from '../routes/TopicModels'
import { VictoryBar, VictoryChart, VictoryTheme } from 'victory'
import './TopicModalContent.scss'
import PaginatedTable, { IPaginatedQueryResult } from './PaginatedTable'
import { Button, Icon, Table } from 'semantic-ui-react'
import config from '../config'
import axios from 'axios'
import { showError } from './FilePreview'

interface IDataRow {
  score: number
  data: string
  cell_id: string
  column_name: string
  column_hidden: boolean
}

interface ITopicModalProps {
  topic: ITopic
  reloadTopics: () => void
}

export const TopicModalContent = ({
  topic,
  reloadTopics,
}: ITopicModalProps) => {
  const [updateIndex, setUpdateIndex] = React.useState(0)
  const [showAll, setShowAll] = React.useState(false)

  const fetchData = async (
    page: number,
  ): Promise<IPaginatedQueryResult<IDataRow>> => {
    const url = `${config.TOPICS}/${topic.id}/cells`
    const { data } = await axios.get(url, {
      params: { page, return_hidden: showAll },
    })
    return data
  }

  const updateTopicColumn = async (
    topicId: string,
    cellId: string,
    isHidden: boolean,
  ) => {
    const url = `${config.TOPICS}/${topicId}/cells/${cellId}`
    try {
      await axios.put(url, {
        is_hidden: isHidden,
      })
      setUpdateIndex(updateIndex + 1)
    } catch (e) {
      showError(e)
    }
  }

  const rowElementGetter = (row: IDataRow) => {
    return (
      <Table.Row
        key={row.cell_id}
        className={row.column_hidden ? 'hidden' : ''}
      >
        <Table.Cell>{Math.floor(row.score * 100)}%</Table.Cell>
        <Table.Cell>{row.column_name}</Table.Cell>
        <Table.Cell>
          <div className={'data-cell'}>
            <span>{row.data}</span>
            <Icon
              name={'eye'}
              onClick={() => {
                updateTopicColumn(topic.id, row.cell_id, !row.column_hidden)
              }}
            />
          </div>
        </Table.Cell>
      </Table.Row>
    )
  }

  return (
    <div className={'topic-modal-content'}>
      <EditableTopicName
        name={topic.name}
        id={topic.id}
        reloadData={reloadTopics}
      />
      <h2>{topic.name}</h2>

      <div className={'chart'}>
        <VictoryChart
          theme={VictoryTheme.material}
          domainPadding={20}
          padding={{ top: 5, bottom: 40, left: 100, right: 5 }}
          style={{ parent: { height: 'auto' } }}
        >
          <VictoryBar
            horizontal
            data={topic.terms}
            x={'original'}
            y={'score'}
          />
        </VictoryChart>
      </div>
      <Button
        onClick={() => {
          setShowAll(!showAll)
        }}
      >
        {showAll ? 'Hide hidden' : 'Show all'}
      </Button>
      <PaginatedTable
        key={updateIndex}
        dataGetter={fetchData}
        headerRow={
          <Table.Row>
            <Table.HeaderCell>Score</Table.HeaderCell>
            <Table.HeaderCell>Column</Table.HeaderCell>
            <Table.HeaderCell>Data</Table.HeaderCell>
          </Table.Row>
        }
        rowElementGetter={rowElementGetter}
      />
    </div>
  )
}
