import React from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'

const DimmedLoader = () => (
  <Dimmer active inverted>
    <Loader>Loading</Loader>
  </Dimmer>
)

export default DimmedLoader
