import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { IFilePreviewProps } from '../routes/FilePreview'
import { Link } from 'react-router-dom'
import {
  Breadcrumb,
  Button,
  Checkbox,
  CheckboxProps,
  Header,
  List,
  Segment,
} from 'semantic-ui-react'
import config from '../config'
import { IColumnInfoData, showError } from '../components/FilePreview'
import { toast } from 'react-toastify'
import DimmedLoader from '../components/DimmedLoader'
import AuthContext from '../AuthContext'

const CreateTrainingData = ({
  match: {
    params: { fileId },
  },
}: RouteComponentProps<IFilePreviewProps>) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [columnInfos, setColumnInfos] = useState<IColumnInfoData[]>([])
  const [selectedTrainingColumns, setSelectedTrainingColumns] = useState<
    string[]
  >([])
  useEffect(() => {
    const fetchMetaData = async () => {
      const url = `${config.FILE_INFO_URL}`
      try {
        setLoading(true)
        const {
          data: { data },
        } = await axios.get(url, {
          params: { id: fileId },
        })
        setColumnInfos(data)
      } catch (e) {
        toast.error('Something went wrong')
      }
      setLoading(false)
    }
    fetchMetaData()
  }, [fileId])

  const onClickCheckboxHandler = (slug: string, enabled: boolean) => (
    _: any,
    { checked }: CheckboxProps,
  ) => {
    if (!enabled) {
      return
    }
    const newTrainingColumns = checked
      ? [...selectedTrainingColumns, slug]
      : selectedTrainingColumns.filter(item => item !== slug)
    setSelectedTrainingColumns(newTrainingColumns)
  }

  const onSubmit = async () => {
    const url = `${config.TRAINING_FILES_URL}`
    try {
      await axios.post(
        url,
        {
          fields: selectedTrainingColumns.join(','),
          id: fileId,
        },
      )
      toast.success(
        'Training set created (can soon be used to train a new model)',
      )
    } catch (error) {
      showError(error)
    }
  }

  return (
    <>
      <Breadcrumb size={'large'}>
        <Breadcrumb.Section link as={Link} to={config.ROUTES.files}>
          Files
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon={'right angle'} />
        <Breadcrumb.Section
          link
          as={Link}
          to={`${config.ROUTES.files}/${fileId}`}
        >
          {fileId}
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon={'right angle'} />
        <Breadcrumb.Section>Create training set</Breadcrumb.Section>
      </Breadcrumb>
      <Segment>
        {loading ? (
          <DimmedLoader />
        ) : (
          <>
            <Segment.Group horizontal>
              <SegmentedSelectableList
                title="Use as training data"
                items={columnInfos}
                selectableType="string"
                onClickCheckboxHandler={onClickCheckboxHandler}
                selectedItems={selectedTrainingColumns}
              />
              <SegmentedSelectableList
                title="Use as score"
                items={columnInfos}
                selectableType="number"
                onClickCheckboxHandler={onClickCheckboxHandler}
                selectedItems={selectedTrainingColumns}
              />
            </Segment.Group>
            <Button color={'green'} onClick={onSubmit}>
              Submit
            </Button>
          </>
        )}
      </Segment>
    </>
  )
}

type IOnClickCheckboxHandler = (
  slug: string,
  enabled: boolean,
) => (_: any, data: CheckboxProps) => void

interface ISegmentedSelectableListProps {
  title: string
  items: IColumnInfoData[]
  selectableType: 'string' | 'number'
  onClickCheckboxHandler: IOnClickCheckboxHandler
  selectedItems: string[]
}

const SegmentedSelectableList = ({
  title,
  items,
  selectableType,
  onClickCheckboxHandler,
  selectedItems,
}: ISegmentedSelectableListProps) => (
  <Segment>
    <Header>{title}</Header>
    <List>
      {items.map(({ type, slugified, original }: IColumnInfoData) => {
        const enabled = type === selectableType
        return (
          <List.Item key={slugified}>
            <Checkbox
              label={original}
              disabled={!enabled}
              onClick={onClickCheckboxHandler(slugified, enabled)}
              checked={selectedItems.includes(slugified) && enabled}
            />
          </List.Item>
        )
      })}
    </List>
  </Segment>
)

export default CreateTrainingData
