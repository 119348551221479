import React, { useEffect, useCallback, useState, useMemo } from 'react'
import { toast } from 'react-toastify'
import axios, { AxiosError } from 'axios'
import { Segment } from 'semantic-ui-react'
import config from '../config'
import { RouteComponentProps, withRouter } from 'react-router'
import queryString from 'query-string'
import FilterPanel from './FilterPanel'
import FilePreviewHeader from './FilePreviewHeader'
import FilePreviewFilters from './FilePreviewFilters'
import FilePreviewContent, { ITablePageInfo } from './FilePreviewContent'
import useDebounce from '../hooks/useDebounce'
import AuthContext from '../AuthContext'

export interface IColumnInfoGroup {
  _id: string
  checked: boolean
  count: number
  percentage: number
}

export interface IColumnInfoData {
  checked: boolean
  groups?: IColumnInfoGroup[]
  original: string
  slugified: string
  type: string
}

interface IColumnInfoMeta {
  total_count: number
}
export interface IColumnInfo {
  data: IColumnInfoData[]
  meta: IColumnInfoMeta
}

interface IFilePreviewContainerProps {
  fileId: string
  predictionId?: string
}

export const defaultColumnInfoResponse: IColumnInfo = {
  data: [],
  meta: { total_count: -1 },
}

interface IFetchInfoArgs {
  fileId: string
}

export const fetchFileInfo = async ({ fileId }: IFetchInfoArgs) => {
  const url = `${config.FILE_INFO_URL}`
  try {
    const { data } = await axios.get(url, {
      params: { id: fileId },
    })
    return data
  } catch (e) {
    showError(e)
  }
}

interface IFetchContentArgs {
  fileId: string
  filterRows?: string
  pageSize?: number
  page?: number
  predictionId?: string
}

const fetchContent = async ({
  fileId,
  filterRows,
  pageSize,
  page,
  predictionId,
}: IFetchContentArgs) => {
  const url = `${config.FILE_CONTENT_URL}`
  return axios.get(url, {
    params: {
      filter: filterRows,
      id: fileId,
      n_page: pageSize,
      page,
      prediction_id: predictionId,
    },
  })
}

export interface IFileCell {
  column_number: number
  score: number | null
  slug: string
  value: string
}

export interface IFileDataRow {
  row_number: number
  cells: IFileCell[]
}

interface IFileMeta {
  count: number
  total_count: number
}

export interface IFileContent {
  data: IFileDataRow[]
  meta: IFileMeta
}

const defaultFileContent: IFileContent = {
  data: [],
  meta: {
    count: 1,
    total_count: 1,
  },
}

export const showError = (error: AxiosError) => {
  if (
    error.response &&
    (error.response.status === 400 || error.response.status === 404)
  ) {
    const { data } = error.response
    if (typeof data === 'object') {
      const errorText =
        (data.error && typeof data.error === 'string' && data.error) ||
        (data.error &&
          Object.entries(data.error)
            .map((value: any) => value.join(': '))
            .join('\n')) ||
        'Something went wrong'
      toast.error(errorText, { type: toast.TYPE.WARNING })
    } else {
      toast.error(data, { type: toast.TYPE.WARNING })
    }
  } else {
    if (error.response) {
      toast.error(`Something went wrong (${error.response.status})`)
      return
    }
    toast.error('Something went wrong')
  }
}

export const getFilterStrings = (search: string) => {
  const parsedSearch = (search && search.length > 1 && search.substr(1)) || ''
  const parsedQuery = queryString.parse(decodeURIComponent(parsedSearch))
  return {
    filterColumnsString: !!parsedQuery.cols
      ? (parsedQuery.cols as string)
      : undefined,
    filterRowsString: !!parsedQuery.rows
      ? (parsedQuery.rows as string)
      : undefined,
  }
}

export const fetchInfoHandler = async (
  fileId: string,
  setLoadingColumnInfo: (a: boolean) => void,
  setColumnInfo: (a: IColumnInfo) => void,
) => {
  try {
    setLoadingColumnInfo(true)
    const data = await fetchFileInfo({ fileId })
    if (data !== undefined) {
      setColumnInfo(data)
    }
  } catch (error) {
    showError(error)
    setColumnInfo(defaultColumnInfoResponse)
  } finally {
    setLoadingColumnInfo(false)
  }
}

const FilePreview = ({
  match: {
    params: { fileId, predictionId },
  },
  location: { search, pathname },
  history,
}: RouteComponentProps<IFilePreviewContainerProps>) => {
  const [loadingColumnInfo, setLoadingColumnInfo] = useState<boolean>(true)
  const [columnInfo, setColumnInfo] = useState<IColumnInfo>(
    defaultColumnInfoResponse,
  )

  const [loadingFileContent, setLoadingFileContent] = useState<boolean>(true)
  const [fileContent, setFileContent] = useState<IFileContent>(
    defaultFileContent,
  )

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(20)

  useEffect(() => {
    if (columnInfo.data.length === 0) {
      setTimeout(() => {
        fetchInfoHandler(fileId, setLoadingColumnInfo, setColumnInfo)
      }, 1000) // Quick hack to prevent error on first load of file
    } else {
      fetchInfoHandler(fileId, setLoadingColumnInfo, setColumnInfo)
    }
  }, [fileId])

  const { filterRowsString, filterColumnsString } = useMemo(
    () => getFilterStrings(search),
    [search],
  )

  const debouncedFilterRowsString = useDebounce(
    filterRowsString,
    600, // is it good enough?
  )

  useEffect(() => {
    const fetchContentHandler = async () => {
      try {
        setLoadingFileContent(true)
        const { data } = await fetchContent({
          fileId,
          filterRows: debouncedFilterRowsString,
          page,
          pageSize,
          predictionId,
        })
        setFileContent(data)
      } catch (error) {
        showError(error)
        setFileContent(defaultFileContent)
      } finally {
        setLoadingFileContent(false)
      }
    }
    fetchContentHandler()
  }, [debouncedFilterRowsString, page, pageSize, fileId, predictionId])

  const pushHistory = useCallback(
    (searchQuery: string) => {
      const url = `${pathname}${searchQuery}`
      history.push(url)
    },
    [history, pathname],
  )

  const onFetchDataHandler = useCallback(
    ({ page: nextPage, pageSize: newPageSize }: ITablePageInfo) => {
      setPage(nextPage)
      setPageSize(newPageSize)
    },
    [],
  )

  return (
    <Segment>
      <div className={'filterable-content'}>
        <div className={'filters'}>
          <FilePreviewFilters
            columnInfo={columnInfo}
            loading={loadingColumnInfo}
            filterColumnsString={filterColumnsString || ''}
            filterRowsString={filterRowsString || ''}
            pushHistory={pushHistory}
          />
        </div>
        <div className={'filterable-data'}>
          <FilePreviewHeader
            loading={loadingFileContent}
            rowCount={fileContent.meta.count}
            totalRowCount={fileContent.meta.total_count}
            fileId={fileId}
          />
          <FilterPanel
            filterRowsString={debouncedFilterRowsString}
            columnInfo={columnInfo}
            loading={loadingFileContent && loadingColumnInfo}
          />
          <FilePreviewContent
            fileContent={fileContent}
            columnInfo={columnInfo}
            loading={loadingFileContent}
            filterColumnsString={filterColumnsString}
            page={page}
            pageSize={pageSize}
            onFetchData={onFetchDataHandler}
          />
        </div>
      </div>
    </Segment>
  )
}

export default withRouter(FilePreview)
