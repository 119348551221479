import React from 'react'
import { Label } from 'semantic-ui-react'
import styled from 'styled-components'
import { getFilterRowsOrDefault } from './FilePreviewFilters'
import { IColumnInfo, IColumnInfoData } from './FilePreview'

interface IFilterPanelProps {
  columnInfo: IColumnInfo
  filterRowsString: string | undefined
  loading: boolean
}

const LabelStyled = styled(Label)`
  && {
    margin-bottom: 5px;
    margin-right: 5px;
  }
`

interface ILabelData {
  key: string
  text: string
}

const FilterPanel = React.memo(
  ({
    filterRowsString = '',
    columnInfo: { data },
    loading,
  }: IFilterPanelProps) => {
    if (loading) {
      return null
    }
    const lookup = Object.fromEntries(
      data.map(({ slugified, original }: IColumnInfoData) => [
        slugified,
        original,
      ]),
    )
    const filterRows = getFilterRowsOrDefault(filterRowsString)
    const labels: ILabelData[] = Object.entries(filterRows).map(
      ([slug, filters]) => {
        const text =
          filters.length === 1
            ? `${lookup[slug]} == "${filters[0]}"`
            : `${lookup[slug]} IN [${filters
                .map(filter => '"' + filter + '"')
                .join(', ')}]`
        return { key: slug, text }
      },
    )

    return (
      <>
        {labels.map(({ key, text }: ILabelData) => (
          <LabelStyled color="teal" key={key}>
            {text}
          </LabelStyled>
        ))}
      </>
    )
  },
)

export default FilterPanel
