import React from 'react'
import config from '../config'
import axios from 'axios'
import { showError } from './FilePreview'
import { IOrganization } from './Settings'
import {
  Button,
  Checkbox,
  CheckboxProps,
  Dropdown,
  Input,
  Loader,
  Table,
  Modal,
  Header,
} from 'semantic-ui-react'
import AuthContext from '../AuthContext'
import { toast } from 'react-toastify'
import { UserSettingsRow } from './UserSettingsRow'

interface INewUserParams {
  email: string
  isAdmin: boolean
  organization: string
  username: string
}

const fetchUsers = async () => {
  const url = `${config.USER_URL}`
  try {
    const { data } = await axios.get(url)
    return data
  } catch (e) {
    showError(e)
  }
}

const postUser = async (params: INewUserParams) => {
  const url = `${config.USER_URL}`
  try {
    const { data } = await axios.post(url, {
      email: params.email,
      is_admin: params.isAdmin,
      organization_id: params.organization,
      username: params.username,
    })
    return data.password
  } catch (e) {
    showError(e)
  }
}

export interface IUser {
  id: string
  username: string
  organization: IOrganization
  isAdmin: boolean
  email: string
}

interface IUserSettingsProps {
  organizations: IOrganization[]
}

const initialNewUserParams = {
  email: '',
  isAdmin: false,
  organization: '',
  username: '',
}

export const UserSettings = ({ organizations }: IUserSettingsProps) => {
  const [users, setUsers] = React.useState<IUser[]>([])
  const [loading, setLoading] = React.useState<boolean>(true)
  const [modalPassword, setModalPassword] = React.useState<string | undefined>(
    undefined,
  )
  const [newUserParams, setNewUserParams] = React.useState<INewUserParams>(
    initialNewUserParams,
  )

  const fetchAndSetUsers = async () => {
    setLoading(true)
    const response = await fetchUsers()
    if (response === undefined) {
      setLoading(false)
      return
    }
    const newUsers = response.data
    setUsers(
      newUsers.map((userData: any) => {
        const u: IUser = {
          id: userData.id,
          isAdmin: userData.is_admin,
          organization: {
            ...userData.organization,
          },
          username: userData.username,
          email: userData.email,
        }
        return u
      }),
    )
    setLoading(false)
  }

  React.useEffect(() => {
    fetchAndSetUsers()
  }, [])

  return (
    <div className={'sub-settings'}>
      <h2>Users</h2>
      {loading ? (
        <Loader active />
      ) : (
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Username</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Is admin</Table.HeaderCell>
              <Table.HeaderCell>Organization</Table.HeaderCell>
              <Table.HeaderCell>Edit</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {users.map(user => (
              <UserSettingsRow
                key={user.id}
                user={user}
                organizations={organizations}
                fetchAndSetUsers={fetchAndSetUsers}
                setModalPassword={setModalPassword}
              />
            ))}
          </Table.Body>
        </Table>
      )}
      <h3>Add new user</h3>
      <Input
        value={newUserParams.username}
        placeholder="Username"
        onChange={event => {
          setNewUserParams({ ...newUserParams, username: event.target.value })
        }}
      />
      <Input
        value={newUserParams.email}
        placeholder="Email"
        onChange={event => {
          setNewUserParams({ ...newUserParams, email: event.target.value })
        }}
      />
      <Checkbox
        onChange={(_, data: CheckboxProps) => {
          setNewUserParams({ ...newUserParams, isAdmin: data.checked! })
        }}
        checked={newUserParams.isAdmin}
        label={'Is admin'}
      />
      <Dropdown
        value={newUserParams.organization}
        placeholder={'Organization'}
        selection
        options={organizations.map(organization => {
          return {
            key: organization.id,
            text: organization.name,
            value: organization.id,
          }
        })}
        onChange={(event, { value }) => {
          const orgId: string = value as string
          setNewUserParams({ ...newUserParams, organization: orgId })
        }}
      />
      <Button
        onClick={async () => {
          setLoading(true)
          const password = await postUser(newUserParams)
          setModalPassword(password)
          setNewUserParams(initialNewUserParams)
          await fetchAndSetUsers()
        }}
      >
        Add
      </Button>
      <Modal
        closeIcon
        open={modalPassword !== undefined}
        onClose={() => {
          setModalPassword(undefined)
        }}
      >
        <Header>User password is set</Header>
        <Modal.Content>
          <p>Send this to the user</p>
          <p>Password: {modalPassword}</p>
        </Modal.Content>
      </Modal>
    </div>
  )
}
