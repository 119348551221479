import React from 'react'
import axios from 'axios'
import config from '../config'
import { Breadcrumb, Button, Icon, Table } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import PaginatedTable, {
  IPaginatedQueryResult,
} from '../components/PaginatedTable'
import moment from 'moment'
import { getStatusElement } from './Models'
import humanizeDuration from 'humanize-duration'
import { ITopicModel } from './TopicModels'
import { showError } from '../components/FilePreview'

interface IGraph {
  id: string
  created_at: string
  status: string
  task_id: string
  node_count: number | null
  edge_count: number | null
  execution_time: number | null
  document_name: string
  document_id: string
  eta: number | null
}

const rowGetter = (item: IGraph) => {
  return (
    <Table.Row key={item.id}>
      <Table.Cell>
        <Link to={`${config.ROUTES.files}/${item.document_id}`}>
          {item.document_name}
        </Link>
      </Table.Cell>
      <Table.Cell>{getStatusElement(item.status)}</Table.Cell>
      <Table.Cell>{item.node_count}</Table.Cell>
      <Table.Cell>{item.edge_count}</Table.Cell>
      <Table.Cell>
        {item.execution_time &&
          humanizeDuration(Math.floor(item.execution_time) * 1000)}
      </Table.Cell>
      <Table.Cell>
        {item.eta &&
          humanizeDuration(Math.floor(item.eta / 60 + 1) * 1000 * 60)}
      </Table.Cell>
      <Table.Cell>
        {moment(item.created_at).format('DD.MM.Y HH:mm:ss')}
      </Table.Cell>
      <Table.Cell>
        {item.status === 'done' && (
          <Button
            onClick={async () => {
              const apiUrl = `${config.GRAPH_URL}/${item.id}.graphml`
              let response
              try {
                response = await axios.get(apiUrl, {
                  params: {},
                  responseType: 'blob',
                })
              } catch (e) {
                showError(e)
              }
              if (response) {
                const a = document.createElement('a')
                const blob = response.data
                const windowUrl = window.URL || window.webkitURL
                const url = windowUrl.createObjectURL(blob)
                a.href = url
                const fileName = `${item!.document_name}.graphml`
                a.download = fileName
                a.click()
                windowUrl.revokeObjectURL(url)
              }
            }}
          >
            Download
          </Button>
        )}
      </Table.Cell>
    </Table.Row>
  )
}

const getData = async (
  page: number,
): Promise<IPaginatedQueryResult<ITopicModel>> => {
  const url = `${config.GRAPH_URL}`
  const { data } = await axios.get(url, {
    params: { page },
  })
  return data
}

const Graphs = () => {
  return (
    <React.Fragment>
      <div className={'breadcrumb-container'}>
        <Breadcrumb size={'large'}>
          <Breadcrumb.Section>Graphs</Breadcrumb.Section>
        </Breadcrumb>
        <Button color={'green'} as={Link} to={config.ROUTES.files}>
          <Icon name={'plus'} />
          New
        </Button>
      </div>
      <PaginatedTable
        headerRow={
          <Table.Row>
            <Table.HeaderCell>Document Name</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Nodes</Table.HeaderCell>
            <Table.HeaderCell>Edges</Table.HeaderCell>
            <Table.HeaderCell>Execution Time</Table.HeaderCell>
            <Table.HeaderCell>ETA</Table.HeaderCell>
            <Table.HeaderCell>Created At</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        }
        rowElementGetter={rowGetter}
        dataGetter={getData}
      />
    </React.Fragment>
  )
}

export default Graphs
