import React from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import config from './config'
import { showError } from './components/FilePreview'

export const parseJwt = (token: string) => {
  // https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(c => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )

  return JSON.parse(jsonPayload)
}

interface IAuthOrganizationData {
  name: string
  id: string
}

export interface IAuthUserData {
  id: string
  name: string
  organization: IAuthOrganizationData
}

export interface IJwtContent {
  userId: string
  organizationId: string
  isAdmin: boolean
  organizationHasLogo: boolean
}

interface IAuthContextData {
  jwtContent: undefined | IJwtContent
  setJwtContent:
    | React.Dispatch<React.SetStateAction<IJwtContent | undefined>>
    | undefined
  token: string | null | undefined
  setToken:
    | React.Dispatch<React.SetStateAction<string | null | undefined>>
    | undefined
  refreshToken: string | null | undefined
  setRefreshToken:
    | React.Dispatch<React.SetStateAction<string | null | undefined>>
    | undefined
  login: (username: string, password: string, key: string) => Promise<void>
  logout: () => void
}

const AuthContext = React.createContext<IAuthContextData>({
  jwtContent: undefined,
  // tslint:disable-next-line:no-empty
  login: async (username: string, password: string, key: string) => {},
  // tslint:disable-next-line:no-empty
  logout: () => {},
  refreshToken: undefined,
  setJwtContent: undefined,
  setRefreshToken: undefined,
  setToken: undefined,
  token: null,
})

const getNewToken = async (token: string, refreshToken: string) => {
  const url = `${config.AUTH_URL}/refresh`
  const response = await axios.post(url, {
    refresh_token: refreshToken,
  })
  const newToken: string = response.data.token
  return newToken
}

const updateToken = async (
  token: string,
  refreshToken: string,
  setToken: React.Dispatch<React.SetStateAction<string | null | undefined>>,
  setJwtContent: React.Dispatch<React.SetStateAction<IJwtContent | undefined>>,
) => {
  try {
    const newRefreshedToken = await getNewToken(token, refreshToken)
    localStorage.setItem('token', newRefreshedToken)
    const jwtResult = parseJwt(newRefreshedToken)
    const jwtContent: IJwtContent = {
      isAdmin: jwtResult.is_admin,
      organizationHasLogo: jwtResult.org_has_logo,
      organizationId: jwtResult.user_org_id,
      userId: jwtResult.user_id,
    }
    setJwtContent(jwtContent)
    setToken(newRefreshedToken)

    axios.defaults.headers.common.Authorization = 'Bearer ' + newRefreshedToken

    return true
  } catch (e) {
    setToken(null)
    if (e.response && e.response.status === 401) {
      toast.warn('Login expired, log back in')
      localStorage.removeItem('token')
      localStorage.removeItem('refresh-token')
      return false
    }
    toast.error('Something went wrong')
    return false
  }
}

export const AuthenticationProvider = (props: any) => {
  const [token, setToken] = React.useState<string | null | undefined>(undefined)
  const [jwtContent, setJwtContent] = React.useState<undefined | IJwtContent>(
    undefined,
  )
  const [refreshToken, setRefreshToken] = React.useState<
    string | null | undefined
  >(undefined)

  const login = async (username: string, password: string, key: string) => {
    const url = `${config.AUTH_URL}`
    try {
      const response = await axios.post(url, {
        key,
        password,
        username,
      })
      const responseToken: string = response.data.token
      const responseRefreshToken: string = response.data.refresh_token

      axios.defaults.headers.common.Authorization = 'Bearer ' + responseToken
      setToken!(responseToken)
      setRefreshToken!(responseRefreshToken)

      const jwtResult = parseJwt(responseToken)

      const responseJwtContent: IJwtContent = {
        isAdmin: jwtResult.is_admin,
        organizationHasLogo: jwtResult.org_has_logo,
        organizationId: jwtResult.user_org_id,
        userId: jwtResult.user_id,
      }
      setJwtContent!(responseJwtContent)

      localStorage.setItem('token', responseToken)
      localStorage.setItem('refresh-token', responseRefreshToken)
    } catch (e) {
      if (e.response) {
        if (e.response.status === 401) {
          const errorString: string = e.response.data.reasons.join('\n')
          toast.error(errorString, { type: toast.TYPE.WARNING })
          return
        }
      }
      showError(e)
    }
  }

  const logout = () => {
    setToken!(null)
    setRefreshToken!(null)
    setJwtContent!(undefined)
    localStorage.removeItem('token')
    localStorage.removeItem('refresh-token')
    delete axios.defaults.headers.common.Authorization
  }

  const value: IAuthContextData = {
    jwtContent,
    login,
    logout,
    refreshToken,
    setJwtContent,
    setRefreshToken,
    setToken,
    token,
  }

  React.useEffect(() => {
    ;(async () => {
      const storedRefreshToken = localStorage.getItem('refresh-token')
      const storedToken = localStorage.getItem('token')
      if (!!storedToken) {
        axios.defaults.headers.common.Authorization = 'Bearer ' + storedToken
      }

      setRefreshToken(storedRefreshToken)

      if (storedToken && storedRefreshToken) {
        const updateSuccesful = await updateToken(
          storedToken,
          storedRefreshToken,
          setToken,
          setJwtContent!,
        )
        if (updateSuccesful) {
          setInterval(() => {
            setToken(curToken => {
              setRefreshToken(curRefreshToken => {
                updateToken(
                  curToken!,
                  curRefreshToken!,
                  setToken,
                  setJwtContent!,
                )
                return curRefreshToken
              })
              return curToken
            })
          }, 1000 * 60 * 20) // Update token every 20 minutes. Expires in 30 minutes.
          return
        }
      }
      setToken(null)
      setRefreshToken(null)
    })()
  }, [])

  return <AuthContext.Provider value={value} {...props} />
}

// export AuthenticationProvider

export default AuthContext
