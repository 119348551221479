import React, { ChangeEvent, FormEvent } from 'react'
import './Login.scss'
import AuthContext, { IJwtContent, parseJwt } from '../AuthContext'
import { Loader } from 'semantic-ui-react'
import config from '../config'
import axios from 'axios'
import { toast } from 'react-toastify'
import { showError } from './FilePreview'

export const Login = () => {
  const [username, setUsername] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [token, setToken] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [onSecondStep, setOnSecondStep] = React.useState(false)

  const { login } = React.useContext(AuthContext)

  const onChangeUsername = (event: ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value)
  }
  const onChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
  }
  const onChangeToken = (event: ChangeEvent<HTMLInputElement>) => {
    setToken(event.target.value)
  }

  const doPreLogin = async () => {
    const url = `${config.AUTH_URL}/pre_login`
    try {
      const response = await axios.post(url, {
        password,
        username,
      })
      return true
    } catch (e) {
      if (e.response && e.response.status === 401) {
        const errorString: string = e.response.data.error
        toast.error(errorString, { type: toast.TYPE.WARNING })
      } else {
        showError(e)
      }
    }
    return false
  }

  const onClickLogin = async (event: FormEvent) => {
    event.preventDefault()
    setLoading(true)
    await login(username, password, token)
    setLoading(false)
  }

  const onClickPreLogin = async (event: FormEvent) => {
    event.preventDefault()
    setLoading(true)
    const succeeded = await doPreLogin()
    setLoading(false)

    if (succeeded) {
      toast.success('You should get an token in your email shortly.')
      setOnSecondStep(true)
    }
  }

  return (
    <div className={'login-container'}>
      <div>
        <h1>Wordloom</h1>
        <h2>Log in</h2>
        <form
          onSubmit={onClickPreLogin}
          target={'#'}
          className={!onSecondStep ? '' : 'non-visible'}
        >
          <input
            placeholder={'Username'}
            type={'text'}
            value={username}
            onChange={onChangeUsername}
          />
          <input
            placeholder={'Password'}
            type={'password'}
            value={password}
            onChange={onChangePassword}
          />
          <button type={'submit'}>Submit{loading && <Loader active />}</button>
        </form>
        <form
          onSubmit={onClickLogin}
          target={'#'}
          className={onSecondStep ? '' : 'non-visible'}
        >
          <input
            placeholder={'2fa key'}
            type={'text'}
            value={token}
            onChange={onChangeToken}
          />
          <button type={'submit'}>Login{loading && <Loader active />}</button>
          <button
            onClick={(e) => {
              e.preventDefault()
              setPassword('')
              setUsername('')
              setOnSecondStep(false)
            }}
          >
            Back
          </button>
        </form>
      </div>
    </div>
  )
}
