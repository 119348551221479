import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { RouteComponentProps } from 'react-router'
import config from '../config'
import { IPrediction } from './Predictions'
import { Breadcrumb, Header } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import FilePreview, { showError } from '../components/FilePreview'

interface IPredictionProps {
  predictionId: string
  fileId?: string
}

const Prediction = ({
  match: {
    params: { predictionId, fileId },
  },
  history,
}: RouteComponentProps<IPredictionProps>) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [prediction, setPrediction] = useState<undefined | IPrediction>(
    undefined,
  )

  useEffect(() => {
    const loadData = async () => {
      const url = `${config.PREDICTIONS_URL}/${predictionId}`
      try {
        setLoading(true)
        const { data } = await axios.get(url)
        const responsePrediction: IPrediction = data
        setPrediction(responsePrediction)
        if (fileId !== responsePrediction.document_id) {
          history.push(
            `/predictions/${predictionId}/${responsePrediction.document_id}`,
          )
        }
      } catch (e) {
        showError(e)
        setPrediction(undefined)
      } finally {
        setLoading(false)
      }
    }
    loadData()
  }, [predictionId, history, fileId])

  return loading ? (
    <span>Loading</span>
  ) : prediction !== undefined ? (
    <>
      <Breadcrumb size={'large'}>
        <Breadcrumb.Section link as={Link} to={config.ROUTES.predictions}>
          Predictions
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon={'right angle'} />
        <Breadcrumb.Section>{prediction!.id}</Breadcrumb.Section>
      </Breadcrumb>
      <FilePreview />
    </>
  ) : (
    <Header>
      The error has occurred during the loading of the data. Please, refresh to
      try again.
    </Header>
  )
}

export default Prediction
