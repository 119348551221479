import React, { useState } from 'react'
import { Input, Form, Button, Icon } from 'semantic-ui-react'
import config from '../config'
import { toast } from 'react-toastify'
import { handleError } from './BlacklistRow'
import axios from 'axios'
import AuthContext from '../AuthContext'

interface IBlacklistInputProps {
  fetchData: () => void
}

const BlacklistInput = ({ fetchData }: IBlacklistInputProps) => {
  const [inputValue, setInputValue] = useState('')
  const [addLoading, setAddLoading] = useState<boolean>(false)

  const options = {
    autoClose: 1500,
  }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }

  const handleSubmit = async () => {
    setAddLoading(true)
    const trimmerWord = inputValue.trim()
    if (trimmerWord.length < 1) {
      return toast.warn('No empty words allowed!', options)
    }
    const url = `${config.BLACKLIST_URL}`
    try {
      await axios.post(
        url,
        { word: inputValue },
      )
      await fetchData()
      setInputValue('')
    } catch (e) {
      handleError(e)
    }
    setAddLoading(false)
  }

  return (
    <Form>
      <Form.Field>
        <Input
          value={inputValue}
          placeholder="to blacklist"
          onChange={handleChange}
        />
      </Form.Field>
      <Button
        loading={addLoading}
        color="green"
        type="submit"
        onClick={handleSubmit}
      >
        <Icon name="plus" />
        Add
      </Button>
    </Form>
  )
}

export default BlacklistInput
