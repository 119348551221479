import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Header, Table, Loader } from 'semantic-ui-react'
import config from '../config'
import { showError } from '../components/FilePreview'
import BlacklistRow from '../components/BlacklistRow'
import BlacklistInput from '../components/BlacklistInput'

export interface IBlacklistWord {
  id: string
  word: string
}

const Blacklist = () => {
  const [items, setItems] = useState<IBlacklistWord[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const fetchData = async () => {
    const url = `${config.BLACKLIST_URL}`
    try {
      const { data } = await axios.get(url)
      setItems(data)
      setLoading(false)
    } catch (e) {
      showError(e)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <React.Fragment>
      <Header size="medium">Blacklist</Header>
      <BlacklistInput fetchData={fetchData} />
      {!loading ? (
        items.length > 0 ? (
          <Table compact basic="very">
            <Table.Body>
              {items.map((blacklistword: IBlacklistWord) => {
                return (
                  <BlacklistRow
                    key={blacklistword.id}
                    blacklistword={blacklistword}
                    fetchData={fetchData}
                  />
                )
              })}
            </Table.Body>
          </Table>
        ) : (
          <Header size="tiny">No items</Header>
        )
      ) : (
        <Loader active />
      )}
    </React.Fragment>
  )
}

export default Blacklist
