const routes = {
  blacklist: '/blacklist',
  files: '/files',
  graphs: '/graphs',
  help: '/help',
  home: '/home',
  models: '/models',
  predictions: '/predictions',
  topic_models: '/topic-models',
  settings: '/settings',
}

const config = {
  API_URL: '/api',
  AUTH_URL: '/auth',
  BLACKLIST_URL: '/blacklist',
  FILE_CONTENT_URL: '/file_content',
  FILE_INFO_URL: '/file_info',
  FILE_URL: '/files',
  GRAPH_URL: '/document_graph',
  MODELS_URL: '/models',
  ORGANIZAITON_URL: '/organizations',
  PREDICTIONS_URL: '/predictions',
  PROJECT_URL: '/projects',
  ROUTES: routes,
  SENTIMENT_URL: '/sentiments',
  SPELL_CHECK_PAGE_SIZE: 20,
  SPELL_CHECK_REFRESH_TIMEOUT: 5000,
  SPELL_CHECK_URL: '/spell_check',
  STORAGE_URL: '/storage',
  TOAST_DELAY: 5000,
  TOPICS: '/topics',
  TOPIC_MODELS_URL: '/topic_models',
  TRAINING_FILES_URL: '/training_files',
  USER_URL: '/users',
}

export default config
