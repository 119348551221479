import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { ITopic, ITopicModel, RowsFilter } from '../routes/TopicModels'
import config from '../config'
import {
  Breadcrumb,
  Button,
  Dropdown,
  Icon,
  Loader,
  Modal,
  Form,
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import {
  defaultColumnInfoResponse,
  fetchInfoHandler,
  getFilterStrings,
  IColumnInfo,
  showError,
} from '../components/FilePreview'
import { Scatter } from 'react-chartjs-2'
import './TopicModel.scss'
import { TopicModalContent } from '../components/TopicModalContent'
import { TopicModelTab } from '../components/TopicModelTab'
import { TopicModelDownloadXlsxModal } from '../components/TopicModelDownloadXlsxModal'
import FilePreviewFilters from '../components/FilePreviewFilters'
import { toast } from 'react-toastify'
import domtoimage from 'dom-to-image'

const BreadcrumbStyled = styled(Breadcrumb)`
  && {
    margin-bottom: 1em;
  }
`

const loadData = async (
  modelId: string,
  setTopicModel: React.Dispatch<React.SetStateAction<ITopicModel | undefined>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  scoreOptions: IScoreOption[],
  setScoreOptions: React.Dispatch<React.SetStateAction<IScoreOption[]>>,
  filterRowString: string,
  threshold: number,
) => {
  const url = `${config.TOPIC_MODELS_URL}/${modelId}`
  try {
    const { data } = await axios.get(url, {
      params: {
        rows: filterRowString,
        threshold,
      },
    })
    const sortedTopicModel = {
      ...data,
      topics: data.topics.sort((first: ITopic, second: ITopic) => {
        if (
          first.score_filtered_document_count ===
          second.score_filtered_document_count
        ) {
          return 0
        }
        if (
          first.score_filtered_document_count <=
          second.score_filtered_document_count
        ) {
          return 1
        }
        return -1
      }),
    }
    setTopicModel(sortedTopicModel)
    if (scoreOptions.length === initialScoreOptions.length) {
      setScoreOptions([...scoreOptions, ...data.sentiment_values])
    }
    setLoading(false)
  } catch (e) {
    showError(e)
  }
}

export interface IScoreOption {
  key: number
  text: string
  value: string
}

interface ILabelChooserProps {
  setLabel: (label: string) => void
  defaultValue: string
  scoreOptions: IScoreOption[]
}

const LabelChooser = ({
  defaultValue,
  setLabel,
  scoreOptions,
}: ILabelChooserProps) => {
  const [labelValue, setLabelValue] = React.useState(defaultValue)

  return (
    <Dropdown
      value={labelValue}
      placeholder={'Organization'}
      selection
      options={scoreOptions}
      onChange={(event, { value }) => {
        setLabelValue(value as string)
        setLabel(value as string)
      }}
    />
  )
}

const initialScoreOptions: IScoreOption[] = [
  {
    key: 0,
    text: 'Count',
    value: 'cnt',
  },
  {
    key: 1,
    text: 'Intra Topic Similarity',
    value: 'its',
  },
  {
    key: 2,
    text: 'Separability',
    value: 'sep',
  },
]

interface ITopicModelContentProps {
  modelId: string
  threshold: number
}

const TopicModelContent = ({ modelId, threshold }: ITopicModelContentProps) => {
  const [xlabel, setXlabel] = useState('sep')
  const [ylabel, setYlabel] = useState('its')

  const [scoreOptions, setScoreOptions] = useState(initialScoreOptions)
  const [loading, setLoading] = useState<boolean>(true)
  const [filtersVisible, setFiltersVisible] = useState<boolean>(false)
  const [refreshing, setRefreshing] = useState(true)
  const [topicModel, setTopicModel] = useState<ITopicModel | undefined>(
    undefined,
  )
  const [scatterElement, setScatterElement] = useState<null | Scatter>(null)
  const [modalTopic, setModalTopic] = useState<undefined | ITopic>(undefined)
  const [downloadXlsxModalOpen, setDownloadXlsxModalOpen] = useState(false)
  const [downloadingPng, setDownloadingPng] = useState(false)
  const [loadingColumnInfo, setLoadingColumnInfo] = useState<boolean>(true)
  const [columnInfo, setColumnInfo] = useState<IColumnInfo>(
    defaultColumnInfoResponse,
  )
  const [filterRowString, setFilterRowString] = useState('')
  const [hiddenTopicIds, setHiddenTopicIds] = useState<string[]>([])

  const getScoreText = (key: string) => {
    const choices = scoreOptions.filter(option => option.value === key)
    if (choices) {
      return choices[0].text
    }
    return '??'
  }

  useEffect(() => {
    if (topicModel && columnInfo.data.length === 0) {
      fetchInfoHandler(
        topicModel.document_id,
        setLoadingColumnInfo,
        setColumnInfo,
      )
    }
  }, [columnInfo.data.length, topicModel])

  const loadAndSetData = async () => {
    await loadData(
      modelId,
      setTopicModel,
      setLoading,
      scoreOptions,
      setScoreOptions,
      filterRowString,
      threshold,
    )
  }

  useEffect(() => {
    ;(async () => {
      setRefreshing(true)
      await loadAndSetData()
      setRefreshing(false)
    })()
  }, [modelId, filterRowString, threshold])

  const minSize = 0
  let maxSize = 0
  if (topicModel && topicModel.topics) {
    // minSize = Math.min(
    //   ...topicModel.topics.map(topic => topic.score_document_count),
    // )
    maxSize = Math.max(
      ...topicModel.topics.map(topic => topic.score_document_count),
    )
  }

  const topicColors =
    topicModel && topicModel.topics
      ? topicModel!.topics!.map((topic, index) => ({
          h: Math.floor((index / (topicModel.topics!.length + 1)) * 360),
          l: Math.floor(70),
          s: Math.floor(50),
        }))
      : []

  const setHoverAction = (index: number) => {
    // https://stackoverflow.com/questions/53764367/how-to-trigger-hover-programmatically-in-chartjs
    const c = scatterElement!.chartInstance
    const rect = c.canvas!.getBoundingClientRect()

    let point = { x: 0, y: 0 }
    if (index >= 0) {
      const meta = c.getDatasetMeta(index)
      const thing: any = meta.data[0]
      point = thing.getCenterPoint()
    }
    const evt = new MouseEvent('mousemove', {
      clientX: rect.left + point.x,
      clientY: rect.top + point.y,
    })
    const node = c.canvas
    node!.dispatchEvent(evt)
  }

  return (
    <div className={'topic-model'}>
      <BreadcrumbStyled size={'large'}>
        <Breadcrumb.Section link as={Link} to={config.ROUTES.topic_models}>
          Topic Models
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon={'right angle'} />
        <Breadcrumb.Section
          link={!loading}
          as={!loading ? Link : undefined}
          to={
            !loading
              ? `${config.ROUTES.files}/${topicModel!.document_id}`
              : undefined
          }
        >
          {loading ? '...' : topicModel!.document_name}
        </Breadcrumb.Section>
      </BreadcrumbStyled>
      {loading ? (
        <Loader active inline />
      ) : (
        <>
          {downloadXlsxModalOpen && (
            <Modal
              open
              closeIcon
              onClose={() => {
                setDownloadXlsxModalOpen(false)
              }}
            >
              <TopicModelDownloadXlsxModal
                topicModel={topicModel!}
                closeModal={() => {
                  setDownloadXlsxModalOpen(false)
                }}
                threshold={threshold}
              />
            </Modal>
          )}
          <Modal
            size={'large'}
            open={modalTopic !== undefined}
            onClose={() => setModalTopic(undefined)}
            closeIcon
          >
            <div className={'topic-modal modal-content'}>
              {(() => {
                if (
                  !topicModel ||
                  !topicModel.topics ||
                  modalTopic === undefined
                ) {
                  return <div />
                }

                return (
                  <div>
                    <TopicModalContent
                      topic={modalTopic}
                      reloadTopics={loadAndSetData}
                    />
                  </div>
                )
              })()}
            </div>
          </Modal>
          {topicModel && (
            <>
              <TopicModelTab
                activeTopicModel={topicModel}
                activeTopicModelId={modelId}
              />
              <div className={'filters-container'}>
                <h3>
                  Topics{' '}
                  <Icon
                    onClick={() => {
                      setFiltersVisible(!filtersVisible)
                    }}
                    name={'filter'}
                  />
                </h3>
                <div className={'filters' + (filtersVisible ? '' : ' hidden')}>
                  <FilePreviewFilters
                    columnInfo={columnInfo}
                    loading={loadingColumnInfo}
                    filterColumnsString={''}
                    filterRowsString={filterRowString}
                    pushHistory={(str: string) => {
                      const filterValues = getFilterStrings(str)
                      setFilterRowString(filterValues.filterRowsString || '')
                    }}
                  />
                </div>
              </div>
            </>
          )}
          {refreshing ? (
            <Loader active inline />
          ) : (
            <>
              <RowsFilter filters={topicModel!.filter_rows_labels} />
              <div className={'topic-container'} id={'topic-container'}>
                <div className={'topic-name-list'}>
                  {topicModel!
                    .topics!.filter(
                      topic => hiddenTopicIds.indexOf(topic.id) === -1,
                    )
                    .map((topic, index) => {
                      const { h, s, l } = topicColors[index]
                      const colorString = `hsla(${h}, ${s}%, ${l}%, 50%)`
                      return (
                        <div
                          key={topic.id}
                          onClick={() => {
                            setModalTopic(topic)
                          }}
                          onMouseEnter={() => {
                            setHoverAction(index)
                          }}
                          onMouseLeave={() => {
                            setHoverAction(-1)
                          }}
                        >
                          <div
                            className={'color-box'}
                            style={{ backgroundColor: colorString }}
                          >
                            {filterRowString.length > 0 && (
                              <span>
                                {Math.floor(
                                  (100 * topic.score_filtered_document_count!) /
                                    topic.score_document_count,
                                )}
                                %
                              </span>
                            )}
                            <div
                              className={'percentage-indicator'}
                              style={{
                                backgroundColor: colorString,
                                width: `${Math.floor(
                                  (100 * topic.score_filtered_document_count!) /
                                    topic.score_document_count,
                                )}%`,
                              }}
                            />
                          </div>
                          <span>
                            {index + 1}. {topic.name}
                          </span>
                          <div className={'hide-icon'}>
                            <Icon
                              name={'hide'}
                              onClick={(e: React.FormEvent) => {
                                setHiddenTopicIds([...hiddenTopicIds, topic.id])
                                e.stopPropagation()
                              }}
                            />
                          </div>
                        </div>
                      )
                    })}
                </div>
                <div className={'topic-graph'}>
                  <Scatter
                    ref={el => {
                      if (el !== null) {
                        setScatterElement(el)
                        const anyEl: any = el
                        const chartInstance: any = anyEl.chartInstance
                        const oldDraw = chartInstance.chart.draw

                        function drawOverride() {
                          oldDraw.apply(chartInstance.chart, arguments)
                          for (
                            let i = 0;
                            i < chartInstance.data.datasets.length;
                            i++
                          ) {
                            const x = chartInstance.getDatasetMeta(i).data[0]
                              ._view.x
                            const y = chartInstance.getDatasetMeta(i).data[0]
                              ._view.y

                            chartInstance.ctx.textAlign = 'center'
                            chartInstance.ctx.textBaseline = 'middle'
                            chartInstance.ctx.fillStyle = 'darkgray'
                            chartInstance.ctx.font =
                              '12px "Helvetica Neue", Helvetica, Arial, sans-serif'
                            chartInstance.ctx.fillText(
                              (i + 1).toString() + '.',
                              x,
                              y,
                            )
                          }
                        }
                        chartInstance.chart.draw = drawOverride
                      }
                    }}
                    height={200}
                    options={{
                      legend: { display: false },
                      onClick: (
                        event?: MouseEvent,
                        activeElements?: Array<{}>,
                      ) => {
                        if (activeElements !== undefined) {
                          const elementInfo: any = activeElements[0]
                          if (elementInfo && topicModel && topicModel.topics) {
                            const visibleTopics = topicModel.topics.filter(t => hiddenTopicIds.indexOf(t.id) < 0)
                            setModalTopic(
                              visibleTopics[elementInfo._datasetIndex],
                            )
                          }
                        }
                      },
                      scales: {
                        xAxes: [
                          {
                            scaleLabel: {
                              display: true,
                              labelString: getScoreText(xlabel),
                            },
                          },
                        ],
                        yAxes: [
                          {
                            scaleLabel: {
                              display: true,
                              labelString: getScoreText(ylabel),
                            },
                          },
                        ],
                      },
                      title: { display: true, text: 'Topics' },
                      tooltips: {
                        callbacks: {
                          label: (tooltipItem, data) => {
                            const label = data!.datasets![
                              tooltipItem!.datasetIndex!
                            ].label as string
                            return label
                          },
                        },
                      },
                    }}
                    data={{
                      datasets: topicModel!
                        .topics!.filter(
                          topic => hiddenTopicIds.indexOf(topic.id) === -1,
                        )
                        .map((topic, index) => {
                          const { h, s, l } = topicColors[index]
                          const colorString = `hsla(${h}, ${s}%, ${l}%, 50%)`
                          const hoverColorString = `hsla(${h}, ${s}%, ${l}%, 70%)`
                          const size =
                            10 +
                            (60 * (topic.score_document_count - minSize)) /
                              (maxSize - minSize)

                          let x = 0
                          if (xlabel === 'cnt') {
                            x = topic.score_document_count
                          } else if (xlabel === 'its') {
                            x = topic.score_td_idf
                          } else if (xlabel === 'sep') {
                            x = topic.score_cosine_similarity
                          } else {
                            x = topic.sentiments![xlabel]
                          }
                          let y = 0
                          if (ylabel === 'cnt') {
                            y = topic.score_document_count
                          } else if (ylabel === 'its') {
                            y = topic.score_td_idf
                          } else if (ylabel === 'sep') {
                            y = topic.score_cosine_similarity
                          } else {
                            y = topic.sentiments![ylabel]
                          }

                          return {
                            backgroundColor: colorString,
                            data: [{ x, y }],
                            hoverBackgroundColor: hoverColorString,
                            label: `${topic.name} - Documents: ${topic.score_document_count}`,
                            pointHoverRadius: size * 1.1,
                            pointRadius: size,
                          }
                        }),
                    }}
                  />
                  <Form.Field>
                    <label>X-Axis</label>
                    <LabelChooser
                      setLabel={setXlabel}
                      defaultValue={xlabel}
                      scoreOptions={scoreOptions}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Y-Axis</label>
                    <LabelChooser
                      setLabel={setYlabel}
                      defaultValue={ylabel}
                      scoreOptions={scoreOptions}
                    />
                  </Form.Field>
                </div>
              </div>
              <div className={'download-buttons'}>
                <Button
                  loading={downloadingPng}
                  onClick={() => {
                    const node = document.getElementById('topic-container')
                    setDownloadingPng(true)

                    domtoimage
                      .toPng(node!)
                      .then((dataUrl: string) => {
                        // console.log('dataUrl', dataUrl)
                        const a = document.createElement('a')
                        a.href = dataUrl
                        const fileName = `${topicModel!.document_name}_${
                          topicModel!.name
                        }.png`
                        a.download = fileName
                        a.click()
                        setDownloadingPng(false)
                      })
                      .catch(_ => {
                        toast.error('Something went wrong')
                      })
                  }}
                >
                  <Icon name={'download'} />
                  .png
                </Button>
                <Button
                  onClick={() => {
                    setDownloadXlsxModalOpen(true)
                  }}
                >
                  <Icon name={'download'} />
                  .xlxs
                </Button>
                {hiddenTopicIds.length > 0 && (
                  <Button
                    onClick={() => {
                      setHiddenTopicIds([])
                    }}
                  >
                    Reset Hidden Topics
                  </Button>
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default TopicModelContent
