import axios from 'axios'
import React, { useState } from 'react'
import {
  Breadcrumb,
  Button,
  Checkbox,
  CheckboxProps,
  Header,
  List,
  Table,
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import config from '../config'
import { toast } from 'react-toastify'
import moment from 'moment'
import { getStatusElement } from './Models'
import { showError } from '../components/FilePreview'
import PaginatedTable, {
  IPaginatedQueryResult,
} from '../components/PaginatedTable'
import AuthContext from '../AuthContext'

export interface ITrainingFile {
  id: string
  status: string
  name: string
  document_name: string
  created_at: string
  row_count: number
  column_names: string[]
}

interface IFileColumnListProps {
  item: ITrainingFile
}

const FileColumnList = ({ item }: IFileColumnListProps) => {
  const [visible, setVisible] = useState<boolean>(false)
  const makeInvisible = () => setVisible(false)
  const makeVisible = () => setVisible(true)
  return visible ? (
    <>
      <List>
        {item.column_names.map((columnName: string) => (
          <List.Item key={columnName}>{columnName}</List.Item>
        ))}
      </List>
      <Button onClick={makeInvisible}>Hide</Button>
    </>
  ) : (
    <Button onClick={makeVisible}>Show</Button>
  )
}

const loadData = async (
  page: number,
): Promise<IPaginatedQueryResult<ITrainingFile>> => {
  const url = `${config.TRAINING_FILES_URL}`
  const { data } = await axios.get(url, {
    params: { page },
  })
  return data
}

const NewModel = () => {
  const [selectedSetIds, setSelectedSetIds] = useState<any>({})

  const onClickCheckbox = (trainingFileId: string, status: boolean) => {
    const newSelected = { ...selectedSetIds }
    newSelected[trainingFileId] = status
    setSelectedSetIds(newSelected)
  }

  const startTraining = async () => {
    const setIds = Object.entries(selectedSetIds)
      .filter(entry => entry[1])
      .map(entry => entry[0])
      .join(',')

    const url = `${config.MODELS_URL}`
    try {
      await axios.post(
        url,
        { set_ids: setIds },
      )
      toast.success('Training job submitted. You can see it on the Models tab.')
    } catch (e) {
      showError(e)
    }
  }

  const rowElementGetter = (item: ITrainingFile) => {
    const onClickHandler = (_: any, data: CheckboxProps) => {
      onClickCheckbox(item.id, data.checked!)
    }
    return (
      <Table.Row
        key={item.id}
        className={selectedSetIds[item.id] === true ? 'active' : ''}
      >
        <Table.Cell>
          {item.status === 'done' && (
            <Checkbox
              onClick={onClickHandler}
              checked={selectedSetIds[item.id] === true}
              label={'Select'}
            />
          )}
        </Table.Cell>
        <Table.Cell>{item.document_name}</Table.Cell>
        <Table.Cell>
          <FileColumnList item={item} />
        </Table.Cell>
        <Table.Cell>{item.name}</Table.Cell>
        <Table.Cell>{item.row_count}</Table.Cell>
        <Table.Cell>
          {moment(item.created_at).format('DD.MM.Y HH:mm:ss')}
        </Table.Cell>
        <Table.Cell>{getStatusElement(item.status)}</Table.Cell>
      </Table.Row>
    )
  }

  return (
    <div>
      <div className={'breadcrumb-container'}>
        <Breadcrumb size={'large'}>
          <Breadcrumb.Section link as={Link} to={config.ROUTES.models}>
            Models
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon={'right angle'} />
          <Breadcrumb.Section>New</Breadcrumb.Section>
        </Breadcrumb>
      </div>
      <React.Fragment>
        <Header>Select data to be used at the training.</Header>
        <PaginatedTable
          headerRow={
            <Table.Row>
              <Table.HeaderCell>Selected</Table.HeaderCell>
              <Table.HeaderCell>Base File</Table.HeaderCell>
              <Table.HeaderCell>Base File Columns</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Rows</Table.HeaderCell>
              <Table.HeaderCell>Created At</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
            </Table.Row>
          }
          rowElementGetter={rowElementGetter}
          dataGetter={loadData}
        />
        <Button
          color={'green'}
          onClick={startTraining}
          disabled={
            !Object.entries(selectedSetIds).filter(entry => entry[1]).length
          }
        >
          Start Training
        </Button>
      </React.Fragment>
    </div>
  )
}

export default NewModel
