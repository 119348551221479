import React from 'react'
import config from '../config'
import axios from 'axios'
import { showError } from './FilePreview'
import { Button, Input, Loader, Table } from 'semantic-ui-react'
import { IOrganization } from './Settings'
import { toast } from 'react-toastify'
import { OrganizationEdit } from './OrganizationEdit'

const postOrganization = async (name: string) => {
  const url = `${config.ORGANIZAITON_URL}`
  try {
    const { data } = await axios.post(url, { name })
    return data
  } catch (e) {
    showError(e)
  }
}

interface IOrganizationSettingsProps {
  organizations: IOrganization[]
  handleFetchOrganizations: () => void
}

export const OrganizationSettings = ({
  organizations,
  handleFetchOrganizations,
}: IOrganizationSettingsProps) => {
  const [loading, setLoading] = React.useState(false)
  const [newOrganizationName, setNewOrganizationName] = React.useState<string>(
    '',
  )

  return (
    <div className={'sub-settings'}>
      <h2>Organizations</h2>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Logo</Table.HeaderCell>
            <Table.HeaderCell>Edit</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {organizations.map(organization => {
            const logoUrl = `${config.API_URL}${config.ORGANIZAITON_URL}/${organization.id}/logo.png`
            return (
              <Table.Row key={organization.id}>
                <Table.Cell>{organization.name}</Table.Cell>
                <Table.Cell>
                  {organization.logo_base64 && (
                    <img src={organization.logo_base64} />
                  )}
                </Table.Cell>
                <Table.Cell>
                  <OrganizationEdit
                    organizationId={organization.id}
                    handleFetchOrganizations={handleFetchOrganizations}
                  />
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
      <div>
        <h3>Add new organization</h3>
        <Input
          value={newOrganizationName}
          placeholder="Organization name"
          onChange={event => {
            setNewOrganizationName(event.target.value)
          }}
        />
        <Button
          loading={loading}
          onClick={async () => {
            setLoading(true)
            await postOrganization(newOrganizationName)
            await handleFetchOrganizations()
            setLoading(false)
            toast.success('Organization added')
          }}
        >
          Add
        </Button>
      </div>
    </div>
  )
}
