import React, { ChangeEvent, useState } from 'react'
import { toast } from 'react-toastify'
import config from '../config'
import axios from 'axios'
import { showError } from './FilePreview'
import { Button } from 'semantic-ui-react'

const resize = (file: File) => {
  return new Promise((resolve, reject) => {
    if (file.type.match(/image.*/)) {
      const reader = new FileReader()
      reader.onload = readerEvent => {
        const image = new Image()
        image.onload = () => {
          const canvas = document.createElement('canvas')
          const maxSize = 200
          let width = image.width
          let height = image.height
          if (width > height) {
            if (width > maxSize) {
              height *= maxSize / width
              width = maxSize
            }
          } else {
            if (height > maxSize) {
              width *= maxSize / height
              height = maxSize
            }
          }
          canvas.width = width
          canvas.height = height
          canvas.getContext('2d')!.drawImage(image, 0, 0, width, height)
          const dataUrl = canvas.toDataURL('image/png')
          resolve(dataUrl)
        }
        image.onerror = () => {
          reject()
        }
        image.src = readerEvent.target!.result as string
      }
      reader.onerror = () => {
        reject()
      }
      reader.readAsDataURL(file)
    } else {
      reject()
      toast.error('File must be an image')
    }
  })
}

const uploadLogo = async (
  fileContent: File | undefined,
  organizationId: string,
) => {
  const resizedBase64File = fileContent ? await resize(fileContent) : undefined
  const url = `${config.ORGANIZAITON_URL}/${organizationId}`
  try {
    const { data } = await axios.put(url, {
      logo_base64: resizedBase64File,
    })
    return data
  } catch (e) {
    showError(e)
  }
}

interface IOrganizaitonEditProps {
  organizationId: string
  handleFetchOrganizations: () => void
}

export const OrganizationEdit = ({
  handleFetchOrganizations,
  organizationId,
}: IOrganizaitonEditProps) => {
  const [active, setActive] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [fileContent, setFileContent] = useState<File | undefined>(undefined)

  const onFileChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const file: File = event.target.files![0]
    setFileContent(file)
  }

  const onUploadHandler = async () => {
    if (fileContent !== undefined) {
      setLoading(true)
      const response = await uploadLogo(fileContent, organizationId)
      await handleFetchOrganizations()
      if (response !== undefined) {
        toast.success('Logo added')
      }
      setLoading(false)
      setFileContent(undefined)
      setActive(false)
    }
  }

  const onRemoveLogoHandler = async () => {
    setLoading(true)
    const response = await uploadLogo(undefined, organizationId)
    await handleFetchOrganizations()
    if (response !== undefined) {
      toast.success('Logo removed')
    }
    setLoading(false)
    setFileContent(undefined)
    setActive(false)
  }

  return (
    <div>
      {active ? (
        <div>
          <input type="file" name="file" onChange={onFileChangeHandler} />
          <Button
            loading={loading}
            onClick={onUploadHandler}
            disabled={fileContent === undefined}
          >
            Change Logo
          </Button>
          <Button
            loading={loading}
            onClick={onRemoveLogoHandler}
          >
            Remove Logo
          </Button>
          <Button
            onClick={() => {
              setActive(false)
            }}
          >
            Cancel
          </Button>
        </div>
      ) : (
        <Button
          onClick={() => {
            setActive(true)
          }}
        >
          Edit
        </Button>
      )}
    </div>
  )
}
