import React from 'react'
import { Loader } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'
import config from '../config'
import './MainMenu.scss'
import logo from '../iloom.png'
import AuthContext from '../AuthContext'
import axios from 'axios'
import { ProjectChooser } from './ProjectChooser'

const { ROUTES: routes } = config

interface IOrganizationLogoProps {
  organizationId: string
}

const OrganizationLogo = ({ organizationId }: IOrganizationLogoProps) => {
  const [base64Logo, setBase64Logo] = React.useState<undefined | string>(
    undefined,
  )

  const fetchLogo = async () => {
    const url = `${config.ORGANIZAITON_URL}/${organizationId}`
    const { data } = await axios.get(url)
    setBase64Logo(data.data.logo_base64)
  }

  React.useEffect(() => {
    fetchLogo()
  }, [])

  return (
    <div>
      {base64Logo ? <img src={base64Logo} /> : <Loader active inline />}
    </div>
  )
}

const MainMenu = () => {
  const { jwtContent, logout } = React.useContext(AuthContext)

  return (
    <div className={'main-menu'}>
      <NavLink
        to={routes.home}
        className={
          jwtContent!.organizationHasLogo ? 'custom-logo' : 'iloom-logo'
        }
      >
        {jwtContent!.organizationHasLogo ? (
          <OrganizationLogo organizationId={jwtContent!.organizationId} />
        ) : (
          <img alt={'[iloom]'} src={logo} />
        )}
      </NavLink>
      <NavLink to={routes.files}>Files</NavLink>
      <NavLink to={routes.predictions}>Predictions</NavLink>
      <NavLink to={routes.topic_models}>Topic Models</NavLink>
      <NavLink to={routes.blacklist}>Blacklist</NavLink>
      {jwtContent!.isAdmin && (
        <React.Fragment>
          <NavLink to={routes.models}>Models</NavLink>
          <NavLink to={routes.graphs}>Graphs</NavLink>
        </React.Fragment>
      )}
      <NavLink to={routes.settings}>Settings</NavLink>
      <div className={'button-container'}>
        <ProjectChooser />
        <button onClick={logout}>Logout</button>
      </div>
    </div>
  )
}

export default MainMenu
