import React, { Dispatch, SetStateAction } from 'react'
import config from '../config'
import axios from 'axios'
import { showError } from './FilePreview'
import { Button, Input, Table } from 'semantic-ui-react'
import { toast } from 'react-toastify'
import { fetchProjects, ProjectContext } from '../ProjectContext'

const postProject = async (name: string) => {
  const url = `${config.PROJECT_URL}`
  try {
    const { data } = await axios.post(url, { name })
    return data
  } catch (e) {
    showError(e)
  }
}

export interface IProject {
  id: string
  name: string
  document_count: number
}

export const ProjectSettings = () => {
  // const [projects, setProjects] = React.useState<IProject[]>([])
  const { setProjects, projects, setProjectId } = React.useContext(ProjectContext)
  const [loading, setLoading] = React.useState(false)
  const [newProjectName, setNewProjectName] = React.useState('')

  const deleteProject = async (projectId: string) => {
    const url = `${config.PROJECT_URL}/${projectId}`
    try {
      await axios.delete(url)
      await fetchProjects(setProjects)
      // setProjectId(undefined)
      setProjectId('')
      toast.success('Project removed')
    } catch (e) {
      showError(e)
    }
  }

  return (
    <div className={'sub-settings'}>
      <h2>Projects</h2>
      {projects.length > 0 ? (
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Files</Table.HeaderCell>
              <Table.HeaderCell>Remove</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {projects.map(project => {
              return (
                <Table.Row key={project.id}>
                  <Table.Cell>{project.name}</Table.Cell>
                  <Table.Cell>{project.document_count}</Table.Cell>
                  <Table.Cell>
                    <Button
                      onClick={() => {
                        deleteProject(project.id)
                      }}
                    >
                      Remove
                    </Button>
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      ) : (
        <h3>No projects yet</h3>
      )}
      <div>
        <h3>Add new project</h3>
        <Input
          value={newProjectName}
          placeholder="Project name"
          onChange={event => {
            setNewProjectName(event.target.value)
          }}
        />
        <Button
          loading={loading}
          onClick={async () => {
            setLoading(true)
            const data = await postProject(newProjectName)
            if (data) {
              await fetchProjects(setProjects)
              toast.success('Project added')
              setNewProjectName('')
            }
            setLoading(false)
          }}
        >
          Add
        </Button>
      </div>
    </div>
  )
}
