import React from 'react'
import { Button } from 'semantic-ui-react'

interface IHiddenProps {
  children: React.ReactNode
}

const Hidden = ({ children }: IHiddenProps) => {
  const [hidden, setHidden] = React.useState(true)

  return (
    <div>
      <Button
        onClick={() => {
          setHidden(!hidden)
        }}
      >
        {hidden ? 'Show' : 'Hide'}
      </Button>
      {!hidden && children}
    </div>
  )
}

export default Hidden
